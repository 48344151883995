import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {API} from '../classes/API'
import { useCookies } from 'react-cookie';
import { notifications } from '@mantine/notifications';

export default function Logout() {
  const [cookies, setCookie, removeCookie] = useCookies(['lwbf']);

    removeCookie('token')
    window.location.replace('/admin');

    return(<></>)
}
