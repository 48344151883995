// import { useState } from 'react';
import { useState, useEffect } from 'react';
import { MantineProvider, Text, TextInput, Textarea, Box, Button, Checkbox, Switch, Radio, Group, SimpleGrid, Stepper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import { API } from '../classes/API';
export default function Appointment() {


    const [active, setActive] = useState(0);
    const [nextButton, setNextButton] = useState('');


const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
        parentFirstName: '',
        parentLastName: '',
        pumpRequest: null,
        email: '',
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: '',
        phoneNumber: '',
        // phone: '',
    },

    validate: {
      parentFirstName: (value) => value.trim().length < 2,
      parentLastName: (value) => value.trim().length < 2,
    },


});



  const formValues = {}
    return (
        <Box maw={500} mx="auto">
            <h1>Borrow a breast pump</h1>
            <p>Your breast pump load request has been sent</p>
            <p>Someone will be in contact with you shortly</p>
      </Box>
    );
  }
