import AdminNav from '../../components/AdminNav';
import {
  MantineProvider,
  Text,
  TextInput,
  Box,
  Button,
  SimpleGrid,
  Textarea,
  Radio,
  Group,
  Checkbox,
  Collapse,
  Stepper,
  MultiSelect,
  rem
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import {useParams} from 'react-router-dom';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
// import { Button, Grid, MultiSelect, SimpleGrid, rem } from '@mantine/core';
import StarterKit from '@tiptap/starter-kit';
import { IconPencil, IconTrash } from '@tabler/icons-react';

import { API } from '../../classes/API';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import FamilyList from './family-list';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';


export default function ConsultForm() {
  const [family, setFamily] = useState<familyProps>();
  const [showPage, setShowPage] = useState(false);

  const [active, setActive] = useState(0);
  const [highestStepVisited, setHighestStepVisited] = useState(active);

  const [infertilityOpened, setInfertilityOpened] = useState(false);
  const [otherHormonalOrEndocrineConditionsOpened, setOtherHormonalOrEndocrineConditionsOpened] = useState(false);
  const [allergiesInFamilyDetailsOpened, setAllergiesInFamilyDetailsOpened] = useState(false);
  const [radiationOpened, setRadiationOpened] = useState(false);
  const [medsVitsHerbsOpened, setMedsVitsHerbsOpened] = useState(false);
  const [spinalCordInjuryOpened, setSpinalCordInjuryOpened] = useState(false);
  const [hyperprolactinomaOpened, setHyperprolactinomaOpened] = useState(false);
  const [endocrineDisruptorsOpened, setEndocrineDisruptorsOpened] = useState(false);
  const [spontaneousConceptionOpened, setSpontaneousConceptionOpened] = useState(false);
  const [medicationInPregnancyOpened, setMedicationInPregnancyOpened] = useState(false);
  const [breastChangesInPregnancyOpened, setBreastChangesInPregnancyOpened] = useState(false);
  const [breastChangesInPubertyOpened, setBreastChangesInPubertyOpened] = useState(false);
  const [healthProblemsInPregnancyOpened, setHealthProblemsInPregnancyOpened] = useState(false);
  // const [visibleBirthTraumaOpened, setVisibleBirthTraumaOpened] = useState(false);
  const [skinClearOpened, setSkinClearOpened] = useState(false);
  const [asymmetriesToFaceOrTorticollisOpened, setAsymmetriesToFaceOrTorticollisOpened] = useState(false);
  const [jaundiceOpened, setJaundiceOpened] = useState(false);
  const [medicalProblemsOpened, setMedicalProblemsOpened] = useState(false);
  const [otherPhysicalAnomaliesOpened, setOtherPhysicalAnomaliesOpened] = useState(false);
  const [unsettledRefluxOpened, setUnsettledRefluxOpened] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const [ selectedCats, setSelectedCats ] = useState([]);

  const [detailsOpened, setDetailsOpened] = useState(new Map<string, boolean>);

  const params = useParams();
  // console.log(params);

  const [cookies, setCookie] = useCookies(['token']);

  // Let's any page be selected
  const anyCrumb = true



  function updateDetailsOpened(k:string, v:boolean)
  {
    setDetailsOpened(
      new Map(
        detailsOpened.set(
          k,
          v
        )
      )
    )
  }



const editor = useEditor({
  extensions: [
    StarterKit,
    Link,
  ],
  onBlur:(e)=>{
    console.log('blur',e,e.editor.getText())
    form.values.appointment.followUp.body = e.editor.getText()
    form.values.appointment.followUp.bodyHTML = e.editor.getHTML()
}
});




  useEffect(
    () => {
      //Runs only on the first render
      const cookieToken = cookies.token
      API.get('/reauth', cookieToken).then(
      (response) =>  {
        if (response.status === 401) {
          setCookie("token", "")
          window.location.replace('/admin/login');
          return <></>
        } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
          setShowPage(true)
        }
      },
      error => {
          console.log('ERROR?!?',error)
      }
    );

            if (params['familyId'] != undefined) {
                API.get('/family/'+params['familyId'],cookieToken).then((response) =>  {

                  if (response.status == 200) {


                    const jsonData = response.body;
                    console.log('got family data',jsonData)
                    setFamily(jsonData);
                    editor?.commands.setContent(jsonData.appointment.followUp.bodyHTML)

                    console.log('family is now',family)

                    // Setting the details text to shown
                    if(jsonData.appointment.infertility === true) {
                      setInfertilityOpened(true)
                    }
                    if(jsonData.appointment.otherHormonalOrEndocrineConditions === true) {
                      setOtherHormonalOrEndocrineConditionsOpened(true)
                    }
                    if(jsonData.appointment.allergiesInFamilyDetails === true) {
                      setAllergiesInFamilyDetailsOpened(true)
                    }
                    if(jsonData.appointment.radiation === true) {
                      setRadiationOpened(true)
                    }
                    if(jsonData.appointment.medsVitsHerbs === true) {
                      setMedsVitsHerbsOpened(true)
                    }
                    if(jsonData.appointment.spinalCordInjury === true) {
                      setSpinalCordInjuryOpened(true)
                    }
                    if(jsonData.appointment.hyperprolactinoma === true) {
                      setHyperprolactinomaOpened(true)
                    }
                    if(jsonData.appointment.endocrineDisruptors === true) {
                      setEndocrineDisruptorsOpened(true)
                    }
                    if(jsonData.appointment.spontaneousConception === true) {
                      setSpontaneousConceptionOpened(false)
                    }
                    if(jsonData.appointment.medicationInPregnancy === true) {
                      setMedicationInPregnancyOpened(true)
                    }
                    if(jsonData.appointment.breastChangesInPregnancy === false) {
                      setBreastChangesInPregnancyOpened(true)
                    }
                    if(jsonData.appointment.breastChangesInPuberty === false) {
                      setBreastChangesInPubertyOpened(true)
                    }
                    if(jsonData.appointment.healthProblemsInPregnancy === true) {
                      setHealthProblemsInPregnancyOpened(true)
                    }
                    /*
                    if(jsonData.appointment.visibleBirthTrauma === true) {
                      setVisibleBirthTraumaOpened(true)
                    }
                    */
                    if(jsonData.appointment.skinClear === true) {
                      setSkinClearOpened(true)
                    }
                    if(jsonData.appointment.asymmetriesToFaceOrTorticollis === true) {
                      setAsymmetriesToFaceOrTorticollisOpened(true)
                    }
                    if(jsonData.appointment.jaundice === true) {
                      setJaundiceOpened(true)
                    }
                    if(jsonData.appointment.medicalProblems === true) {
                      setMedicalProblemsOpened(true)
                    }
                    if(jsonData.appointment.otherPhysicalAnomalies === true) {
                      setOtherPhysicalAnomaliesOpened(true)
                    }
                    if(jsonData.appointment.unsettledReflux === true) {
                      setUnsettledRefluxOpened(true)
                    }
                    if(jsonData.appointment.motherAnaemia !== 'no') {
                      updateDetailsOpened(`motherAnaemia`, true)
                    }
                    if(jsonData.appointment.motherSmoker !== 'no') {
                      updateDetailsOpened(`motherSmoker`, true)
                    }
                    if(jsonData.appointment.motherDiabetes !== 'no') {
                      updateDetailsOpened(`motherDiabetes`, true)
                    }
                    if(jsonData.appointment.motherMentalHealthIssues !== 'no') {
                      updateDetailsOpened(`motherMentalHealthIssues`, true)
                    }
                    if(jsonData.appointment.thyroid !== 'no') {
                      updateDetailsOpened(`thyroid`, true)
                    }
                    if(jsonData.appointment.breastSurgery !== 'no') {
                      updateDetailsOpened(`breastSurgery`, true)
                    }





                    if (jsonData.appointment.mastitis !== 'no') {
                      updateDetailsOpened(`mastitis`, true)
                    }
                    if (jsonData.appointment.engorgement !== 'no') {
                      updateDetailsOpened(`engorgement`, true)
                    }
                    if (jsonData.appointment.milkBlisters !== 'no') {
                      updateDetailsOpened(`milkBlisters`, true)
                    }
                    if (jsonData.appointment.blockedDucts !== 'no') {
                      updateDetailsOpened(`blockedDucts`, true)
                    }
                    if (jsonData.appointment.bacterialInfection !== 'no') {
                      updateDetailsOpened(`bacterialInfection`, true)
                    }
                    if (jsonData.appointment.soreCrackedBleedingBruising !== 'no') {
                      updateDetailsOpened(`soreCrackedBleedingBruising`, true)
                    }
                    if (jsonData.appointment.nippleVasospasm !== 'no') {
                      updateDetailsOpened(`nippleVasospasm`, true)
                    }
                    if (jsonData.appointment.tubularBreasts !== 'no') {
                      updateDetailsOpened(`tubularBreasts`, true)
                    }











                    // The chilren are an array, so let's loop through and set the values
                    // family?.children.map((child) {
                    console.log('setting children data', jsonData.children);


                    // const childDataTest:childDataProps[] = [];

                    jsonData.children.forEach((child:childProps) => {
                      console.log('child',child)

                      console.log('VBT: ',child.visibleBirthTrauma);
                      if (child.visibleBirthTrauma === true) {
                        updateDetailsOpened(`visibleBirthTraumaOpened_${child.id}`, true)
                      }
                      if (child.skinClear === false) {
                        updateDetailsOpened(`skinClearOpened_${child.id}`, true)
                      }
                      if (child.asymmetriesToFaceOrTorticollis === true) {
                        updateDetailsOpened(`asymmetriesToFaceOrTorticollisOpened_${child.id}`, true)
                      }
                      if (child.jaundice === true) {
                        updateDetailsOpened(`jaundiceOpened_${child.id}`, true)
                      }
                      if (child.medicalProblems === true) {
                        updateDetailsOpened(`medicalProblemsOpened_${child.id}`, true)
                      }
                      if (child.otherPhysicalAnomalies === true) {
                        updateDetailsOpened(`otherPhysicalAnomaliesOpened_${child.id}`, true)
                      }
                      if (child.unsettledReflux === true) {
                        updateDetailsOpened(`unsettledRefluxOpened_${child.id}`, true)
                      }
                      if (child.bfWithinFirstTwoHours === false) {
                        updateDetailsOpened(`bfWithinFirstTwoHours_${child.id}`, true)
                      }
                      if (child.immediateProlongedSkinToSkin === false) {
                        updateDetailsOpened(`immediateProlongedSkinToSkin_${child.id}`, true)
                      }
                      if (child.resusAtBirth === true) {
                        updateDetailsOpened(`resusAtBirth_${child.id}`, true)
                      }
                      if (child.separationInEarlyDays === true) {
                        updateDetailsOpened(`separationInEarlyDays_${child.id}`, true)
                      }
                      if (child.medsForBaby === true) {
                        updateDetailsOpened(`medsForBaby_${child.id}`, true)
                      }
                      if (child.placentalProblems === true) {
                        updateDetailsOpened(`placentalProblems_${child.id}`, true)
                      }




                    })


                    form.setValues(jsonData)
                  }
                });
            }
            if (cookieToken === '') {
                window.location.replace('/admin/login');
                return
            }
        },
        [editor]
    );

    const handleStepChange = (nextStep: number) => {
      const isOutOfBounds = nextStep > 6 || nextStep < 0;

      if (isOutOfBounds || form.validate().hasErrors) {
        return;
      }
      setActive(nextStep);
      setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
      window.scrollTo(0, 0)
    };


   // Allow the user to freely go back and forth between visited steps.
   // TODO add error checking here
   const shouldAllowSelectStep = (step: number) => anyCrumb || (highestStepVisited >= step && active !== step); //&& !form.validate().hasErrors;

    const cookieToken = cookies.token
    const form = useForm({
      validateInputOnBlur: true,
      initialValues: {
        parentFirstName: '',
        parentLastName: '',
        dateOfBirth: '',
        // babyDateOfBirth: '',
        appointment: {
          partnerFirstName: '',
          partnerLastName: '',
          reasonForConsultation : '',
          feedingHistory : '',
          pregnancyHistory : '',
          spontaneousConception : null,
          spontaneousConceptionDetail : '',
          medicationInPregnancy : null,
          medicationInPregnancyDetail : '',
          breastChangesInPregnancy : '',
          breastChangesInPregnancyDetail : '',
          breastChangesInPuberty : '',
          breastChangesInPubertyDetail : '',
          colostrumHarvesting : '',
          healthProblemsInPregnancy : '',
          healthProblemsInPregnancyDetail : '',
          birthEarlyPostnatalPeriod : '',
          medicationsInLabour : '',
          bloodLoss : '',
          pnDayOfLacto : '',
          additionalComments : '',
          feedingBreastMilk: false,
          feedingExpressedBreastMilk: false,
          feedingFormulaMilk: false,
          feedingDonorMilk: false,
          feedingSolidFood: false,
          feedingHelpNippleShields: '',
          feedingHelpDummy: '',
          usingNippleCream : '',
          usingExpressing : '',
          frequency : '',
          volume : '',
          ebm : '',
          ebmVolumeAndGivenMethod : '',
          formula : '',
          formulaVolumeAndGivenMethod : '',

          motherAnaemia : '',
          motherAnaemiaDetails : '',
          motherSmoker : '',
          motherSmokerDetails : '',
          motherDiabetes : '',
          motherDiabetesDetails : '',
          motherMentalHealthIssues : '',
          motherMentalHealthIssuesDetails : '',
          infertility : '',
          infertilityDetails : '',
          otherHormonalOrEndocrineConditions : '',
          otherHormonalOrEndocrineConditionsDetails : '',
          thyroid : '',
          thyroidDetails : '',
          allergiesInFamily : '',
          allergiesInFamilyDetails : '',
          bmi : '',
          diet : '',
          hypertension : '',
          breastSurgery : '',
          breastSurgeryDetails : '',
          radiation : '',
          radiationDetails : '',
          medsVitsHerbs : '',
          medsVitsHerbsDetails : '',
          feelings : '',
          spinalCordInjury : '',
          spinalCordInjuryDetails : '',
          hyperprolactinoma : '',
          HyperprolactinomaDetails : '',
          endocrineDisruptors : '',
          endocrineDisruptorsDetails : '',
          mastitis : '',
          mastitisDetails : '',
          engorgement : '',
          engorgementDetails : '',
          milkBlisters : '',
          milkBlistersDetails : '',
          blockedDucts : '',
          blockedDuctsDetails : '',
          bacterialInfection : '',
          bacterialInfectionDetails : '',
          soreCrackedBleedingBruising : '',
          soreCrackedBleedingBruisingDetails : '',
          nippleVasospasm : '',
          nippleVasospasmDetails : '',
          tubularBreasts : '',
          tubularBreastsDetails : '',
          supportDiscussion : '',
          pumpLoan : '',
          suggestions : '',
          followUpAppointment : '',

          followUp: {
            body: '',
            bodyHTML: '',

          },

        },

        children: [
          {
            birthTypePlace: '',
            bfWithinFirstTwoHours: false,
            bfWithinFirstTwoHoursDetails: '',
            immediateProlongedSkinToSkin: false,
            immediateProlongedSkinToSkinDetails: '',
            resusAtBirth: false,
            resusAtBirthDetails: '',
            separationInEarlyDays: false,
            separationInEarlyDaysDetails: '',
            medsForBaby: false,
            medsForBabyDetails: '',
            vitaminK: false,
            placentalProblems: false,
            placentalProblemsDetails: '',
            birth: '',
            wetNappies: 0,
            heavy: false,
            urineColour: '',
            dirtyNappies: 0,
            dirtyNappyColour: '',
            dirtyNappyConsistency: '',
            earlyCues: false,
            feedsResponsively: false,
            averageLengthOfFeeds: false,
            /*
            nippleNormalColour: false,
            */
            rooting: false,
            wideGape: false,
            normalMer: false,
            suckSwallow: '',
            babyRelaxed: false,
            secondBreastOffered: false,
            comfortableForMum: false,
            facingBreastAligned: false,
            asymmetricDeepLatch: false,
            comesOffAfterFeed: false,
            nippleToNose: false,
            closeContactWithBody: false,

            visibleBirthTrauma: false,
            visibleBirthTraumaDetail: '',
            skinClear: false,
            skinClearDetail: '',
            /*
            evidenceOfThrush: false,
            */
            // toneAlertTempColour: '',
            chin: '',
            dehydration: '',
            asymmetriesToFaceOrTorticollis: false,
            asymmetriesToFaceOrTorticollisDetail: '',
            jaundice: false,
            jaundiceDetail: '',
            midlineAnomaliesUmbilicalHernia: false,
            midlineAnomaliesReproductiveOrgans: false,
            midlineAnomaliesSacralDimple: false,
            medicalProblems: false,
            medicalProblemsDetail: '',
            otherPhysicalAnomalies: false,
            otherPhysicalAnomaliesDetail: '',
            unsettledReflux: false,
            unsettledRefluxDetail: '',
            elevation: '',
            frenulum: '',
            extension: '',
            palate: '',

            previousFrenulotomy: false,
            reattachment: false,
            commentsOnOralAssessment: '',

            thrushAuxiliary: false,
            thrushPerianal: false,
            thrushTongue: false,
            thrushInguinalFold: false,
          }
        ],
      },
    })

const weightFields = [];
for (let i = 0; i < 5; i++) {
  weightFields.push(
    <p><DateInput/> <TextInput /></p>
  )
}





const sectionNames = [
  'Tongue Tie',
  'Milk Transfer',
  'Breast Compressions',
  'Pumping',
  'Positioning and Attachment',
  'Koala Hold',
  'Exaggerated latch',
  'Periodontal syringe',
  'Finger feeding with tube',
  'Vitamin D',
  'Switch nursing',
  'Paced bottle feeding',
  'Nipple shields',
  'Skin to skin',
  'Blockages/mastitis',
  'SNS',
  'Reasons for low supply',
  'PCOS info',
  'Donor milk',
  'Galactagogues',
  'Blood tests',
  'Coming off shield',
  'Feeding cues:',
  'Feeding cues newborn:',
  'Dropping top ups safely:',
  'Triple feeding suggestion:',
  'Treatment for sore nipples:',
  'Supplements via bottle:',
  'Elevated side lying:',
  'Slow flow teat:',
  'Laid back position:',
  'Laid back position with large breasts:',
  'Breastfeeding with large breasts:',
  'Info on Dummies:',
  'Osteo:',
  'Floppy larynx:',
  'Safe sleep:',
  'Nappy output:',
  'Cafes:',
  'Domperidone',
]

const sectionContent = [
  "With your consent, I assessed ((Baby name))’s tongue function to see if there is a restriction in tongue movement that could be causing a feeding issue. The palate appeared ((normal)). The tongue appeared to move well to the ((right and left)). He ((can/cannot)) extend his tongue past his gum ridge and does/does not elevate the tongue well. Due to the ((lateral movement to the right)) being restricted, and other symptoms including ((weight gain issue)), we decided I would refer ((Baby name)) for an appointment with the tongue tie team at the James Paget University Hospital. You will hear from them regarding an appointment. Your patient information leaflet on what to expect is here: https://www.jpaget.nhs.uk/media/468075/CH-76-Restrictive-Lingual-Frenulum-Tongue-tie-Trust-print-v1-web.pdf",
  "These two videos are great for understanding how to see if a baby is drinking well at the breast. How do I know my baby is getting enough milk? - YouTube and How to recognise when a baby is drinking well - YouTube",
  "Use breast compressions to increase effectiveness of ((baby name))’s time at the breast. You don't need to use them whilst their suck to swallow ratio is 1:1 as you can’t get more effective than that! But they are helpful when they are swallowing less frequently https://breastfeeding.support/what-is-breast-compression/",
  "Here is our pumping guide: Expressing breastmilk | My Site (lowestoftandwaveneybreastfeeding.co.uk).  Pumping 8-12  times per day will help improve your milk supply. We also have a guide to using your breast pump: Breast Pump Guide | My Site (lowestoftandwaveneybreastfeeding.co.uk). It includes information on sterilising and breast milk storage.",
  "Tips for getting a deep latch are here Positioning and attachment | My Site (lowestoftandwaveneybreastfeeding.co.uk) . Make sure you latch baby with their head tipped back and clear so that their chin is doing all the work.",
  "Video of Koala Hold: https://youtu.be/puw8Hy0wMiM?feature=shared",
  "This is an excellent video on a technique called the ‘exaggerated latch’ which I suggest you try. This helps get a deeper latch. https://www.youtube.com/watch?v=41fC0fQs1P8",
  "You can use a periodontal syringe to finger feed (baby’s name) your breast milk. You can also use this to supplement at the breast. How-To guide and safety precautions here: Peridontal Syringe use | My Site (lowestoftandwaveneybreastfeeding.co.uk)",
  "Finger feeding with a tube device can help teach babies to suck more actively. How-to videos here: Finger Feeding to Supplement a Breastfeeding Infant - YouTube and (229) Syringe Feeding your Baby - YouTube.",
  "Vitamin D guidelines for babies Baby Vitamins | Baby | Start for Life (www.nhs.uk).",
  "If (baby’s name) gets sleepy or frustrated on one breast then switch them to the other. You can go back and forth between breasts as frequently as you need to keep the feed active. Watch for (baby’s name) to stop actively sucking and swallowing. If breast compressions don’t get (baby’s name) to start feeding again, swap to the other breast. You may find initially you have to repeat this very frequently. As (baby’s name) becomes less sleepy/frustrated at the breast then things will improve.",
  "Offer a ((volume)) of ((expressed milk/formula)) after breastfeeds to support (baby’s name) to gain weight. Use paced bottle feeding as this is safer and healthier for babies. Please see: https://www.midyorks.nhs.uk/responsive-bottle-feeding/",
  "Here is some guidance on how to use a nipple shield: https://www.lowestoftandwaveneybreastfeeding.co.uk/nipple-shields",
  "Having skin to skin contact whenever possible can really help with feeding, milk supply, bonding and baby’s brain development",
  "Information on blocked ducts/mastitis: Blocked ducts, Mastitis, Abscess. Blebs. | My Site (lowestoftandwaveneybreastfeeding.co.uk).",
  "DIY at-breast supplementer can be a great way to give baby their extra milk. Tips for using the supplementer here DIY Supplemental nursing system | My Site (lowestoftandwaveneybreastfeeding.co.uk)",
  "We discussed your reduced milk supply. I am not able to diagnose a reason behind it, but I can offer possible explanations that may account for it. We discussed how it could be due to a combination of factors, including (reduced breast stimulation/breast surgery/breast injury/stressful time in hospital/your diagnosis of PCOS).\n\nThis article outlines further reasons for reduce milk supply: https://breastfeeding.support/reasons-low-milk-supply/",
  "This is an excellent article, explaining PCOS and its potential impact on milk supply: https://breastfeeding.support/polycystic-ovary-syndrome-breastfeeding/ It has very good links within it.\n\nAlthough PCOS can impact milk supply, this isn't the case for all mothers -supply very much varies from woman to woman. We discussed plans to give your milk supply a good chance to increase and how breastfeeding doesn't have to be ‘all or nothing’ - any breast milk, whether for days, months or years, is valuable to you and your baby. Feeding the babies at the breast via an at-breast supplementer with formula is also an option: Tips for using the supplementer here DIY Supplemental nursing system | My Site (lowestoftandwaveneybreastfeeding.co.uk)",
  "You could consider the use of donor breast milk for ((baby name)) - here is more information https://humanmilkfoundation.org/hearts-milk-bank/need-support/  If you would like to apply for this you would need to contact the Hearts Milk Bank to request this, and I would email them to back-up your request.",
  "You could consider taking a Galactagogue (medications/herbs/foods thought to increase supply) to help increase your supply. Here is some information so that you can make an informed choice. Please check with your GP before taking any galactogogue to ensure it will be suitable for you and any current medications/conditions: https://breastfeeding.support/what-is-a-galactagogue/ and https://ibconline.ca/information-sheets/herbal-remedies-for-milk-supply/",
  "We can request that your GP check your blood levels to see if your thyroid, iron levels, hormone levels etc are influencing your milk supply. If you decided you would like this, I can write to your GP to back up your request.",
  "Tips for coming off nipple shields: https://breastfeeding.support/weaning-from-a-nipple-shield/",
  "Watch for feeding cues and feed when ((Baby name)) shows these.",
  "Whilst establishing feeding, and getting back up to birth weight, if baby doesn’t show cues by 3 hrs since the beginning of last feed then I suggest waking them to feed to ensure a bare minimum of 8 feeds per 24hrs. At night feed by 4 hours. 8-12 feeds per day is a typical range of feeds for a newborn - with 11-12 being the average.",
  "As milk transfer at the breast improves, and we are confident her weight gain is appropriate, then ‘top up’ feeds can reduce gradually. We have a guide on how to do this safely: https://www.lowestoftandwaveneybreastfeeding.co.uk/dropping-top-ups",
  "Limit the feed/top up/pump process to a maximum of one hour so that it is sustainable for you. This may mean limiting the breastfeed to around 30 minutes as a temporary measure whilst working on supply.",
  "https://breastfeeding.support/treatments-sore-nipples/",
  "Offer ((volume)) of ((expressed milk/formula)) after breastfeeds.",
  "An alternative technique is ‘Elevated side lying’, used to feed a baby struggling with paced bottle feeding bottle feeding https://www.feedeatspeak.co.uk/blog/2018/8/14/dummy-blog#:~:text=This%20is%20a%20great%20position,them%20to%20vomit%20or%20reflux",
  "No matter the age of the baby, ensure the bottle has a slow flow teat.",
  "I suggest trying the laid back position/natural breastfeeding: https://www.youtube.com/watch?v=ZJan8xCNgY4",
  "Here’s a great video on the laid back position with large breasts: https://www.youtube.com/watch?v=584nv1oNxvw",
  "Guidance on breastfeeding with large breasts:\nhttps://breastfeeding.support/breastfeeding-with-large-breasts/",
  "Information on dummy use: https://www.basisonline.org.uk/dummies/",
  "Some babies experience bodily tensions from pregnancy and birth and some families find their baby benefits from Cranial Osteopathy. Here is some more information: https://breastfeeding.support/cranial-osteopathy-or-craniosacral-therapy/ If this is something you would like to pursue, here is the link to finding a local practitioner: https://www.osteopathy.org.uk/register-search/practices/?location=Norfolk&radius=5& surn ame=®istration= It is important to check they are trained to treat babies",
  "((baby name)) makes a squeaking noise when breathing. This happens at all times/only during feed. You report this has been since birth. Sometimes this noise can be due to a floppy larynx however I am not a medical professional therefore cannot diagnose - I suggested discussing this with your GP.",
  "Information on safe sleep: https://www.unicef.org.uk/babyfriendly/wp-content/uploads/sites/2/2018/08/Caring-for-your-baby-at-night-web.pdf    There is an App that helps provides you with a ‘bed-sharing decision guide’ so you can make an informed decision about ((baby’s name))’s sleep space: https://www.basisonline.org.uk/infant-sleep-info-app/",
  "Monitor nappy output - any concerns, contact your midwife/Health Visitor/GP. https://www.lowestoftandwaveneybreastfeeding.co.uk/nappies",
  "Consider attending one of our feeding cafes: https://www.lowestoftandwaveneybreastfeeding.co.uk/breastfeeding-cafes",
  "We discussed the option of contacting your GP for medication that may increase your milk supply (Domperidone). Here is some more detailed information Domperidone and breastfeeding - The Breastfeeding Network. If you would like to ask your GP about a domperidone prescription I can write a letter supporting this, please let me know if you’re interested.",
]

const categoryChanged = (val:any) => {
  setSelectedCats(val);
}


const addSection = () => {
  selectedCats.map((cat) => {
    for (const k in sectionNames) {
      const v = sectionNames[k]
      if (v == cat) {
        editor?.commands.insertContent('<p><strong>'+cat+'</strong></p><p>'+sectionContent[k]+'</p>')
        continue
      }
    }
  })
  setSelectedCats([])
  if (editor instanceof Editor) {
    form.values.appointment.followUp.body = editor.getText()
    form.values.appointment.followUp.bodyHTML = editor.getHTML()
  }
  return
}




const data = Object.values(sectionNames)



    const pageContent = (
        <Box maw={800} mx="auto" my="lg">
<h1>Consultation Form</h1>






<form onSubmit={form.onSubmit(
                    (values, _event) => {
                        const parentDOB = new Date(values.dateOfBirth)
                        values.dateOfBirth = parentDOB.getFullYear()+'-'+(parentDOB.getMonth()+1)+'-'+parentDOB.getDate()
                        // values.babyDateOfBirth = babyDOB.getFullYear()+'-'+(babyDOB.getMonth()+1)+'-'+babyDOB.getDate()

                        /*
                        for(let i=0; i<values.babies.length;i++){
                          const tmpDate = new Date(values.babies[i].dateOfBirth)
                          values.babies[i].dateOfBirth = tmpDate.getFullYear()+'-'+(tmpDate.getMonth()+1)+'-'+tmpDate.getDate()
                          // values.babyFirstName[i] = values.babies[i].firstName
                          // values[`babyFirstName1`] = values.babies[i].firstName
                        }

                        values.babyCount = values.babies.length
                        values[`babyFirstName1`] = values.babies[0].firstName
                        values['babyLastName1'] = values.babies[0].lastName
                        values['babyDateOfBirth1'] = values.babies[0].dateOfBirth
                        values['babyWeight1'] = values.babies[0].weights
                        if (values.babies.length > 1) {
                          values[`babyFirstName2`] = values.babies[1].firstName
                          values['babyLastName2'] = values.babies[1].lastName
                          values['babyDateOfBirth2'] = values.babies[1].dateOfBirth
                          values['babyWeight2'] = values.babies[1].weights
                        }
                        if (values.babies.length > 2) {
                          values[`babyFirstName3`] = values.babies[2].firstName
                          values['babyLastName3'] = values.babies[2].lastName
                          values['babyDateOfBirth3'] = values.babies[2].dateOfBirth
                          values['babyWeight3'] = values.babies[2].weights
                        }
*/


                        console.log('test')

                        console.log('vals',values);
                        setSubmitLoader(true)
                        API.put(`/family/${params['familyId']}/consult`, values, cookieToken).then((response) => {
                          if (response.status === 200) {
                                notifications.show({
                                  id: 'setUser',
                                  color: 'teal',
                                  message: 'Consultation data was updated',
                                  icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                                  loading: false,
                                  autoClose: 2000,
                                })
                          } else {
                            notifications.show({
                              id: 'setUser',
                              color: 'red',
                              message: 'Error updating the consultation data',
                              icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                              loading: false,
                              autoClose: 2000,
                            })

                          }
                          setSubmitLoader(false)
                        })
                    },
                    (validationErrors, _values, _event) => {
                    console.log('ERRORS',validationErrors)
                    console.log('FORM VALS',_values)
                    // API.test();
                    //API.pist
                    }
                )}>




  <p><strong>IBCLC visiting:</strong> {family?.user?.firstName} {family?.user?.lastName}</p>
  <p><strong>Date:</strong> {family?.appointment.dateOfAppointment}</p>
  <p><strong>Unavailability:</strong> {family?.appointment.unavailability}</p>



  <Stepper active={active} onStepClick={setActive} breakpoint="sm">
    <Stepper.Step
      label="Mother & Pregnancy"
      description=""
      allowStepSelect={shouldAllowSelectStep(0)}
    >
      <p><strong>Mothers name:</strong> {family?.parentFirstName} {family?.parentLastName}</p>
      <p><strong>Pronoun:</strong> {family?.parentPronoun}</p>
      <p><strong>Date of birth:</strong> {family?.dateOfBirth}</p>

      <p><strong>Second parent name:</strong>: {family?.appointment.partnerFirstName} {family?.appointment.partnerLastName}</p>

      <TextInput
        label="Second parent first name"
        // value={family?.appointment.partnerFirstName}
        {...form.getInputProps('appointment.partnerFirstName')}
      />

      <TextInput
        label="Second parent last name"
        // value={family?.appointment.partnerLastName}
        {...form.getInputProps('appointment.partnerLastName')}
      />

      <Textarea
        label="Reason for today's consultation?"
        {...form.getInputProps('appointment.reasonForConsultation')}
      ></Textarea>

      <Textarea
        label="Feeding history w/previous children"
        {...form.getInputProps('appointment.feedingHistory')}
      ></Textarea>

      <Textarea
        label="Pregnancy History"
        {...form.getInputProps('appointment.pregnancyHistory')}
      ></Textarea>

      <Radio.Group
        name="spontaneousConception"
        label={`Spontaneous conception`}
        // withAsterisk
        my="md"
        {...form.getInputProps('appointment.spontaneousConception')}
        value={`${form.values.appointment.spontaneousConception}`}
      >
        <Group mt="xs">
          <Radio value="true" label="Yes" onClick={()=>setSpontaneousConceptionOpened(false)} />
          <Radio value="false" label="No" onClick={()=>setSpontaneousConceptionOpened(true)} />
        </Group>
      </Radio.Group>
      <Collapse in={spontaneousConceptionOpened}>
        <Textarea
          label="Details:"
          name="spontaneousConceptionDetail"
          {...form.getInputProps('appointment.spontaneousConceptionDetail')}
        />
      </Collapse>

      <Radio.Group
        name="medicationInPregnancy"
        label="Medication in pregnancy"
        // withAsterisk
        my="md"
        {...form.getInputProps('appointment.medicationInPregnancy')}
        value={`${form.values.appointment.medicationInPregnancy}`}
      >
        <Group mt="xs">
          <Radio value="true" label="Yes" onClick={()=>setMedicationInPregnancyOpened(true)} />
          <Radio value="false" label="No" onClick={()=>setMedicationInPregnancyOpened(false)} />
        </Group>
      </Radio.Group>
      <Collapse in={medicationInPregnancyOpened}>
        <Textarea
          label="Details:"
          name="medicationInPregnancyDetail"
          {...form.getInputProps('appointment.medicationInPregnancyDetail')}
        />
      </Collapse>

      <Radio.Group
        name="breastChangesInPregnancy"
        label="Breast changes/ increase in pregnancy"
        // withAsterisk
        my="md"
        {...form.getInputProps('appointment.breastChangesInPregnancy')}
        value={`${form.values.appointment.breastChangesInPregnancy}`}
      >
        <Group mt="xs">
          <Radio value="true" label="Yes" onClick={()=>setBreastChangesInPregnancyOpened(false)} />
          <Radio value="false" label="No" onClick={()=>setBreastChangesInPregnancyOpened(true)} />
        </Group>
      </Radio.Group>
      <Collapse in={breastChangesInPregnancyOpened}>
        <Textarea
          label="Details:"
          name="breastChangesInPregnancyDetail"
          {...form.getInputProps('appointment.breastChangesInPregnancyDetail')}
        />
      </Collapse>

      <Radio.Group
        name="breastChangesInPuberty"
        label="Breast changes/ increase in puberty"
        // withAsterisk
        my="md"
        {...form.getInputProps('appointment.breastChangesInPuberty')}
        value={`${form.values.appointment.breastChangesInPuberty}`}
      >
        <Group mt="xs">
          <Radio value="true" label="Yes" onClick={()=>setBreastChangesInPubertyOpened(false)} />
          <Radio value="false" label="No" onClick={()=>setBreastChangesInPubertyOpened(true)} />
        </Group>
      </Radio.Group>
      <Collapse in={breastChangesInPubertyOpened}>
        <Textarea
          label="Details:"
          name="breastChangesInPubertyDetail"
          {...form.getInputProps('appointment.breastChangesInPubertyDetail')}
        />
      </Collapse>









    </Stepper.Step>
    <Stepper.Step
      label="Baby & birth"
      description=""
      allowStepSelect={shouldAllowSelectStep(1)}
    >


      <Radio.Group
        name="colostrumHarvesting"
        label="Colostrum harvesting"
        // withAsterisk
        my="md"
        {...form.getInputProps('appointment.colostrumHarvesting')}
        value={`${form.values.appointment.colostrumHarvesting}`}
      >
        <Group mt="xs">
          <Radio value="true" label="Yes" />
          <Radio value="false" label="No" />
        </Group>
      </Radio.Group>

      <Radio.Group
        name="healthProblemsInPregnancy"
        label="Physical or Mental Health problems in pregnancy"
        // withAsterisk
        my="md"
        {...form.getInputProps('appointment.healthProblemsInPregnancy')}
        value={`${form.values.appointment.healthProblemsInPregnancy}`}
      >
        <Group mt="xs">
          <Radio value="true" label="Yes" onClick={()=>setHealthProblemsInPregnancyOpened(true)} />
          <Radio value="false" label="No" onClick={()=>setHealthProblemsInPregnancyOpened(false)} />
        </Group>
      </Radio.Group>
      <Collapse in={healthProblemsInPregnancyOpened}>
        <Textarea
          label="Details:"
          name="healthProblemsInPregnancyDetail"
          {...form.getInputProps('appointment.healthProblemsInPregnancyDetail')}
        />
      </Collapse>

      <Textarea
        label="Birth and early postnatal period"
        name="birthEarlyPostnatalPeriod"
        {...form.getInputProps('appointment.birthEarlyPostnatalPeriod')}
      ></Textarea>




<h3>Children:</h3>
      {
        family?.children.map((child, index) => (

          <>
            {family?.children.length > 1 && <p><strong>Child #{index+1}</strong></p>}
            <p><strong>Name:</strong> {child.firstName} {child.lastName}</p>
            <p><strong>Date of Birth:</strong> {child.dateOfBirth}</p>


      <Textarea
        label="Birth type + place"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`children.${index}.birthTypePlace`)}
        value={form.values.children[index].birthTypePlace}
      ></Textarea>



      <Radio.Group
        label="BF within first 2 hours"
        // withAsterisk
        my="md"
        {...form.getInputProps(`children.${index}.bfWithinFirstTwoHours`)}
        value={`${form.values.children[index].bfWithinFirstTwoHours}`}
      >
        <Group mt="xs">
          <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`bfWithinFirstTwoHours_${child.id}`, false)} />
          <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`bfWithinFirstTwoHours_${child.id}`, true)} />
        </Group>
      </Radio.Group>
      <Collapse in={detailsOpened?.get(`bfWithinFirstTwoHours_${child.id}`)!!}>
      <Textarea
        label="Details"
        {...form.getInputProps(`children.${index}.bfWithinFirstTwoHoursDetails`)}
        value={form.values.children[index].bfWithinFirstTwoHoursDetails}
      ></Textarea>
</Collapse>

      <Radio.Group
        // name={`immediateProlongedSkinToSkin_${child.id}`}
        label="Immediate, prolonged skin to skin"
        // withAsterisk
        my="md"
        // {...form.getInputProps(`immediateProlongedSkinToSkin_${child.id}`)}
        {...form.getInputProps(`children.${index}.immediateProlongedSkinToSkin`)}
        value={`${form.values.children[index].immediateProlongedSkinToSkin}`}
      >
        <Group mt="xs">
          <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`immediateProlongedSkinToSkin_${child.id}`, false)} />
          <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`immediateProlongedSkinToSkin_${child.id}`, true)} />
        </Group>
      </Radio.Group>
      <Collapse in={detailsOpened?.get(`immediateProlongedSkinToSkin_${child.id}`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`children.${index}.immediateProlongedSkinToSkinDetails`)}
        value={form.values.children[index].immediateProlongedSkinToSkinDetails}
      ></Textarea>
</Collapse>

      <Radio.Group
    // name={`resusAtBirth_${child.id}`}
    label="Resus at birth"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`resusAtBirth_${child.id}`)}
    {...form.getInputProps(`children.${index}.resusAtBirth`)}
    value={`${form.values.children[index].resusAtBirth}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`resusAtBirth_${child.id}`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`resusAtBirth_${child.id}`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`resusAtBirth_${child.id}`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`children.${index}.resusAtBirthDetails`)}
        value={form.values.children[index].resusAtBirthDetails}
      ></Textarea>
</Collapse>


<Radio.Group
    // name={`separationInEarlyDays_${child.id}`}
    label="Separation in early days"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`separationInEarlyDays_${child.id}`)}
    {...form.getInputProps(`children.${index}.separationInEarlyDays`)}
    value={`${form.values.children[index].separationInEarlyDays}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`separationInEarlyDays_${child.id}`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`separationInEarlyDays_${child.id}`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`separationInEarlyDays_${child.id}`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`children.${index}.separationInEarlyDaysDetails`)}
        value={form.values.children[index].separationInEarlyDaysDetails}
      ></Textarea>
</Collapse>


<Radio.Group
    // name={`medsForBaby_${child.id}`}
    label="Meds for baby after birth"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`medsForBaby_${child.id}`)}
    {...form.getInputProps(`children.${index}.medsForBaby`)}
    value={`${form.values.children[index].medsForBaby}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`medsForBaby_${child.id}`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`medsForBaby_${child.id}`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`medsForBaby_${child.id}`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`children.${index}.medsForBabyDetails`)}
        value={form.values.children[index].medsForBabyDetails}
      ></Textarea>
</Collapse>


<Radio.Group
    // name={`vitaminK_${child.id}`}
    label="Vitamin K"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`vitaminK_${child.id}`)}
    {...form.getInputProps(`children.${index}.vitaminK`)}
    value={`${form.values.children[index].vitaminK}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>



<Radio.Group
    // name={`placentalProblems_${child.id}`}
    label="Placental problems"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`placentalProblems_${child.id}`)}
    {...form.getInputProps(`children.${index}.placentalProblems`)}
    value={`${form.values.children[index].placentalProblems}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`placentalProblems_${child.id}`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`placentalProblems_${child.id}`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`placentalProblems_${child.id}`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`children.${index}.placentalProblems`)}
        value={form.values.children[index].placentalProblemsDetails}
      ></Textarea>
</Collapse>




<p>Weight History</p>
{
        child.weights.map((weight, index) => (
          <>
          <p>{weight.date} - {weight.weight}</p>
          </>
        ))
  }


<Textarea
    label="Birth"
    name={`birth_${child.id}`}
    // {...form.getInputProps(`child.${index}.birth`)}
    {...form.getInputProps(`children.${index}.birth`)}
></Textarea>





<p>Nappy output</p>


<Radio.Group
    // name={`wetNappies_${child.id}`}
    label="Wet nappies (last 24 hrs)"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`wetNappies_${child.id}`)}
    {...form.getInputProps(`children.${index}.wetNappies`)}
    value={`${form.values.children[index].wetNappies}`}
>
  <Group mt="xs">
    <Radio value="1" label="1" />
    <Radio value="2" label="2" />
    <Radio value="3" label="3" />
    <Radio value="4" label="4" />
    <Radio value="5" label="5" />
    <Radio value="6" label="6" />
    <Radio value="7" label="6+" />
  </Group>
</Radio.Group>


<Radio.Group
    // name={`heavy_${child.id}`}
    label="Heavy"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`heavy_${child.id}`)}
    {...form.getInputProps(`children.${index}.heavy`)}
    value={`${form.values.children[index].heavy}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>


<Radio.Group
    // name={`urineColour_${child.id}`}
    label="Urine colour"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`urineColour_${child.id}`)}
    {...form.getInputProps(`children.${index}.urineColour`)}
    value={`${form.values.children[index].urineColour}`}
>
  <Group mt="xs">
    <Radio value="clear" label="Clear" />
    <Radio value="pale" label="Pale" />
    <Radio value="yellow" label="Yellow" />
    <Radio value="dark" label="Dark" />
  </Group>
</Radio.Group>


<Radio.Group
    // name={`dirtyNappies_${child.id}`}
    label="Dirty nappies (last 24 hrs)"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`dirtyNappies_${child.id}`)}
    {...form.getInputProps(`children.${index}.dirtyNappies`)}
    value={`${form.values.children[index].dirtyNappies}`}
>
  <Group mt="xs">
    <Radio value="1" label="1" />
    <Radio value="2" label="2" />
    <Radio value="3" label="3" />
    <Radio value="4" label="4" />
    <Radio value="5" label="5" />
    <Radio value="6" label="6" />
    <Radio value="7" label="6+" />
  </Group>
</Radio.Group>




<Radio.Group
    // name={`dirtyNappyColour_${child.id}`}
    label="Colour"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`dirtyNappyColour_${child.id}`)}
    {...form.getInputProps(`children.${index}.dirtyNappyColour`)}
    value={`${form.values.children[index].dirtyNappyColour}`}
>
  <Group mt="xs">
    <Radio value="mec" label="Mec" />
    <Radio value="brown" label="Brown" />
    <Radio value="green" label="Green" />
    <Radio value="yellow" label="Yellow" />
  </Group>
</Radio.Group>



<Radio.Group
    // name={`dirtyNappyConsistency_${child.id}`}
    label="Consistency"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`dirtyNappyConsistency_${child.id}`)}
    {...form.getInputProps(`children.${index}.dirtyNappyConsistency`)}
    value={`${form.values.children[index].dirtyNappyConsistency}`}
>
  <Group mt="xs">
    <Radio value="explosive" label="Explosive" />
    <Radio value="liquid" label="Liquid" />
    <Radio value="normal" label="Normal" />
  </Group>
</Radio.Group>





          </>
        ))
      }

      <Textarea
        label="Medications in labour/PN + reason"
        name="medicationsInLabour"
        {...form.getInputProps('appointment.medicationsInLabour')}
      ></Textarea>


      <TextInput
        label="Blood loss"
        name="bloodLoss"
        {...form.getInputProps('appointment.bloodLoss')}
        rightSection="mls"
      />



<Radio.Group
    name={`pnDayOfLacto`}
    label="PN Day of Lacto 2"
    // withAsterisk
    my="md"
    {...form.getInputProps(`appointment.pnDayOfLacto`)}
    defaultValue={`${form.values.appointment.pnDayOfLacto}`}
>
  <Group mt="xs">
    <Radio value="1" label="1" />
    <Radio value="2" label="2" />
    <Radio value="3" label="3" />
    <Radio value="4" label="4" />
    <Radio value="5" label="5" />
    <Radio value="6" label="6" />
    <Radio value="7" label="7" />
    <Radio value="8" label="7+" />
  </Group>
</Radio.Group>




      <Textarea
        label="Additional comments"
        name="additionalComments"
        {...form.getInputProps('appointment.additionalComments')}
      ></Textarea>





{/*
<p><strong>Current use of</strong></p>
<Group mt="xs">
    <Checkbox
      // value="usingNippleShield"
      label="Nipple shield"
      {...form.getInputProps('appointment.feedingHelpNippleShield', { type: 'checkbox' })}
    />
    <Checkbox
      // value="usingDummy"
      label="Dummy"
      {...form.getInputProps('appointment.feedingHelpDummy', { type: 'checkbox' })}
    />
    <Checkbox
      // value="usingNippleCream"
      label="Nipple cream/Hydrogel pads"
      {...form.getInputProps('appointment.usingNippleCream', { type: 'checkbox' })}
    />
    <Checkbox
      // value="usingExpressing"
      label="Expressing:Hand/manual/electric"
      {...form.getInputProps('appointment.usingExpressing', { type: 'checkbox' })}
    />
</Group>
*/}

<p><strong>Currently using the following to help with feeding baby:</strong></p>
<Group mt="xs">
    <Checkbox
      label="Nipple shields"
      {...form.getInputProps(`appointment.feedingHelpNippleShields`, { type: 'checkbox' })}
    />
    <Checkbox
      label="Bottles"
      {...form.getInputProps(`appointment.feedingHelpBottles`, { type: 'checkbox' })}
    />
    <Checkbox
      label="Dummy"
      {...form.getInputProps('appointment.feedingHelpDummy', { type: 'checkbox' })}
    />
    <Checkbox
      label="SNS"
      {...form.getInputProps('appointment.feedingHelpSNS', { type: 'checkbox' })}
    />
    <Checkbox
      label="Cup"
      {...form.getInputProps('appointment.feedingHelpCup', { type: 'checkbox' })}
    />
    <Checkbox
      label="Finger feeding"
      {...form.getInputProps('appointment.feedingHelpFingerFeeding', { type: 'checkbox' })}
    />
</Group>




<p><strong>Currently feeding baby:</strong></p>
<Group mt="xs">
    <Checkbox
      label="Breastmilk"
      {...form.getInputProps(`appointment.feedingBreastMilk`, { type: 'checkbox' })}
    />
    <Checkbox
      label="Expressed breastmilk"
      {...form.getInputProps(`appointment.feedingExpressedBreastMilk`, { type: 'checkbox' })}
    />
    <Checkbox
      label="Formula milk"
      {...form.getInputProps('appointment.feedingFormulaMilk', { type: 'checkbox' })}
    />
    <Checkbox
      label="Donar milk"
      {...form.getInputProps('appointment.feedingDonorMilk', { type: 'checkbox' })}
    />
    <Checkbox
      label="Solid food"
      {...form.getInputProps('appointment.feedingSolidFood', { type: 'checkbox' })}
    />
</Group>












<TextInput
  label="Frequency:"
  name="frequency"
  {...form.getInputProps('appointment.frequency')}
/>
<TextInput
  label="Volume:"
  name="volume"
  {...form.getInputProps('appointment.volume')}
/>
<TextInput
  label="EBM:"
  name="ebm"
  {...form.getInputProps('appointment.ebm')}
/>
<TextInput
  label="Volume and given method:"
  name="ebmVolumeAndGivenMethod"
  {...form.getInputProps('appointment.ebmVolumeAndGivenMethod')}
/>
<TextInput
  label="Formula:"
  name="formula"
  {...form.getInputProps('appointment.formula')}
/>
<TextInput
  label="Volume and given method:"
  name="formulaVolumeAndGivenMethod"
  {...form.getInputProps('appointment.formulaVolumeAndGivenMethod')}
/>



    </Stepper.Step>
    <Stepper.Step
      label="Baby history & examination"
      description=""
      allowStepSelect={shouldAllowSelectStep(2)}
    >


<p>Baby history and examination:</p>


{
        family?.children.map((child, index) => (
          <>
            {family?.children.length > 1 && <p><strong>Child #{index+1}</strong></p>}
            <p><strong>Name:</strong> {child.firstName} {child.lastName}</p>
            <Radio.Group
              name={`visibleBirthTrauma_${child.id}`}
              label="Visible birth trauma"
              // withAsterisk
              my="md"
              // {...form.getInputProps(`visibleBirthTrauma_${child.id}`)}
              // defaultValue={`${form.values.children[index].visibleBirthTrauma}`}
              {...form.getInputProps(`children.${index}.visibleBirthTrauma`)}
              value={`${form.values.children[index].visibleBirthTrauma}`}
              >
              <Group mt="xs">
                <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`visibleBirthTraumaOpened_${child.id}`, true)} />
                <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`visibleBirthTraumaOpened_${child.id}`, false)} />
              </Group>
            </Radio.Group>
            <Collapse in={detailsOpened?.get(`visibleBirthTraumaOpened_${child.id}`)!!}>
              <Textarea
                label="Details:"
                // name={`visibleBirthTraumaDetail_${child.id}`}
                // {...form.getInputProps(`child.${child.id}.visibleBirthTraumaDetail`)}
                // defaultValue={`${form.values.children[index].visibleBirthTraumaDetail}`}
                {...form.getInputProps(`children.${index}.visibleBirthTraumaDetail`)}
                />
            </Collapse>

            <Radio.Group
              // name={`skinClear_${child.id}`}
              label="Skin clear"
              // withAsterisk
              my="md"
              // {...form.getInputProps(`skinClear_${child.id}`)}
              // defaultValue={`${form.values.children[index].skinClear}`}
              {...form.getInputProps(`children.${index}.skinClear`)}
              value={`${form.values.children[index].skinClear}`}
              >
              <Group mt="xs">
                <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`skinClearOpened_${child.id}`, false)} />
                <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`skinClearOpened_${child.id}`, true)} />
              </Group>
            </Radio.Group>
            <Collapse in={detailsOpened?.get(`skinClearOpened_${child.id}`)!!}>
              <Textarea
                label="Details:"
                // name={`skinClearDetail_${child.id}`}
                // {...form.getInputProps(`child.${index}.skinClearDetail`)}
                {...form.getInputProps(`children.${index}.skinClearDetail`)}

                // defaultValue={`${form.values.children[index].skinClearDetail}`}
              />
            </Collapse>




            <p><strong>Evidence of thrush</strong></p>
            <Checkbox
              // name={`evidenceOfThrushAuxiliary_${child.id}`}
              label="Auxiliary"
              // {...form.getInputProps(`evidenceOfThrushAuxiliary_${child.id}`, { type: 'checkbox' })}
              {...form.getInputProps(`children.${index}.thrushAuxiliary`, { type: 'checkbox' })}
              />
            <Checkbox
              // name={`evidenceOfThrushPerianal_${child.id}`}
              label="perianal"
              // {...form.getInputProps(`evidenceOfThrushPerianal_${child.id}`, { type: 'checkbox' })}
              {...form.getInputProps(`children.${index}.thrushPerianal`, { type: 'checkbox' })}
            />
            <Checkbox
              // name={`evidenceOfThrushTongue_${child.id}`}
              label="tongue"
              // {...form.getInputProps(`evidenceOfThrushTongue_${child.id}`, { type: 'checkbox' })}
              {...form.getInputProps(`children.${index}.thrushTongue`, { type: 'checkbox' })}
            />
            <Checkbox
              // name={`evidenceOfThrushInguinalFold_${child.id}`}
              label="inguinal fold"
              // {...form.getInputProps(`evidenceOfThrushInguinalFold_${child.id}`, { type: 'checkbox' })}
              {...form.getInputProps(`children.${index}.thrushInguinalFold`, { type: 'checkbox' })}
            />


            <Textarea
              // name={`toneAlertTempColour_${child.id}`}
              label="Tone/Alert/Temp/Colour"
              // {...form.getInputProps(`child.${child.id}.toneAlertTempColour`)}
              // defaultValue={`${form.values.children[index].toneAlertTempColour}`}
              {...form.getInputProps(`children.${index}.toneAlertTempColour`)}
            ></Textarea>


            <Radio.Group
                // name={`chin_${child.id}`}
                label="Chin"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`chin_${child.id}`)}
                // defaultValue={`${form.values.children[index].chin}`}
                {...form.getInputProps(`children.${index}.chin`)}
                >
              <Group mt="xs">
                <Radio value="normal" label="Normal" />
                <Radio value="receding" label="Receding" />
                <Radio value="asymmetrical" label="Asymmetrical" />
                <Radio value="tightOpening" label="Tight opening" />
              </Group>
            </Radio.Group>





            <Radio.Group
                // name={`dehydration_${child.id}`}
                label="Evidence of dehydration"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`dehydration_${child.id}`)}
                // defaultValue={`${form.values.children[index].dehydration}`}
                {...form.getInputProps(`children.${index}.dehydration`)}
            >
              <Group mt="xs">
                <Radio value="sunkenFontanelles" label="Sunken fontanelles" />
                <Radio value="skinElasticity" label="Skin elasticity" />
                <Radio value="dryMouth" label="Dry mouth" />
                <Radio value="lethargic" label="Lethargic" />
                <Radio value="sleepy" label="Sleepy" />
              </Group>
            </Radio.Group>



            <Radio.Group
                // name={`asymmetriesToFaceOrTorticollis_${child.id}`}
                label="Asymmetries to face or torticollis"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`asymmetriesToFaceOrTorticollis_${child.id}`)}
                // defaultValue={`${form.values.children[index].asymmetriesToFaceOrTorticollis}`}
                {...form.getInputProps(`children.${index}.asymmetriesToFaceOrTorticollis`)}
                value={`${form.values.children[index].asymmetriesToFaceOrTorticollis}`}
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`asymmetriesToFaceOrTorticollisOpened_${child.id}`, true)} />
                <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`asymmetriesToFaceOrTorticollisOpened_${child.id}`, false)} />
              </Group>
            </Radio.Group>
            <Collapse in={detailsOpened?.get(`asymmetriesToFaceOrTorticollisOpened_${child.id}`)!!}>
              <Textarea
                label="Details:"
                // name={`asymmetriesToFaceOrTorticollisDetail_${child.id}`}
                // {...form.getInputProps(`child.${child.id}.asymmetriesToFaceOrTorticollisDetail`)}
                // defaultValue={`${form.values.children[index].asymmetriesToFaceOrTorticollisDetail}`}
                {...form.getInputProps(`children.${index}.asymmetriesToFaceOrTorticollisDetail`)}
              />
            </Collapse>


            <Radio.Group
              // name={`jaundice_${child.id}`}
              label="Jaundice (current/historical)"
              // withAsterisk
              my="md"
              // {...form.getInputProps(`jaundice_${child.id}`)}
              // defaultValue={`${form.values.children[index].jaundice}`}
              {...form.getInputProps(`children.${index}.jaundice`)}
              value={`${form.values.children[index].jaundice}`}
              >
              <Group mt="xs">
                <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`jaundiceOpened_${child.id}`, true)} />
                <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`jaundiceOpened_${child.id}`, false)} />
              </Group>
            </Radio.Group>
            <Collapse in={detailsOpened?.get(`jaundiceOpened_${child.id}`)!!}>
              <Textarea
                label="Details:"
                // name={`jaundiceDetail_${child.id}`}
                // {...form.getInputProps(`child.${child.id}.jaundiceDetail`)}
                // defaultValue={`${form.values.children[index].jaundiceDetail}`}
                {...form.getInputProps(`children.${index}.jaundiceDetail`)}
              />
            </Collapse>


            <p>Midline anomalies</p>

            <Group mt="xs">
              <Checkbox
                label="Umbilical hernia"
                {...form.getInputProps(`children.${index}.midlineAnomaliesUmbilicalHernia`, { type: 'checkbox' })}
              />
              <Checkbox
                label="Reproductive organs"
                {...form.getInputProps(`children.${index}.midlineAnomaliesReproductiveOrgans`, { type: 'checkbox' })}
              />
              <Checkbox
                label="Sacral dimple"
                {...form.getInputProps(`children.${index}.midlineAnomaliesSacralDimple`, { type: 'checkbox' })}
              />
            </Group>

            <Radio.Group
              // name={`medicalProblems_${child.id}`}
              label="Medical problems (includ. cardiac/ respiratory/ med)"
              // withAsterisk
              my="md"
              // {...form.getInputProps(`child.${child.id}.medicalProblems`)}
              // defaultValue={`${form.values.children[index].medicalProblems}`}
              {...form.getInputProps(`children.${index}.medicalProblem`)}
              >
              <Group mt="xs">
                <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`medicalProblemsOpened_${child.id}`, true)} />
                <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`medicalProblemsOpened_${child.id}`, false)} />
              </Group>
            </Radio.Group>
            <Collapse in={detailsOpened?.get(`medicalProblemsOpened_${child.id}`)!!}>
              <Textarea
                label="Details:"
                // name={`medicalProblemsDetail_${child.id}`}
                // {...form.getInputProps(`child.${child.id}.medicalProblemsDetail`)}
                {...form.getInputProps(`children.${index}.medicalProblemsDetail`)}
              />
            </Collapse>

            <Radio.Group
              // name={`otherPhysicalAnomalies_${child.id}`}
              label="Other physical anomalies"
              // withAsterisk
              my="md"
              // {...form.getInputProps(`otherPhysicalAnomalies_${child.id}`)}
              // defaultValue={`${form.values.children[index].otherPhysicalAnomalies}`}
              {...form.getInputProps(`children.${index}.otherPhysicalAnomalies`)}
              >
              <Group mt="xs">
                <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`otherPhysicalAnomaliesOpened_${child.id}`, true)} />
                <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`otherPhysicalAnomaliesOpened_${child.id}`, false)} />
              </Group>
            </Radio.Group>
            <Collapse in={detailsOpened?.get(`otherPhysicalAnomaliesOpened_${child.id}`)!!}>
              <Textarea
                label="Details:"
                // name={`otherPhysicalAnomaliesDetail_${child.id}`}
                // {...form.getInputProps(`child.${child.id}.otherPhysicalAnomaliesDetail`)}
                {...form.getInputProps(`children.${index}.otherPhysicalAnomaliesDetail`)}
                />
            </Collapse>






            <Radio.Group
              // name={`unsettledReflux_${child.id}`}
              label="Unsettled/Reflux?"
              // withAsterisk
              my="md"
              // {...form.getInputProps(`unsettledReflux_${child.id}`)}
              // defaultValue={`${form.values.children[index].unsettledReflux}`}
              {...form.getInputProps(`children.${index}.unsettledReflux`)}
              >
              <Group mt="xs">
                <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`unsettledRefluxOpened_${child.id}`, true)} />
                <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`unsettledRefluxOpened_${child.id}`, false)} />
              </Group>
            </Radio.Group>
            <Collapse in={detailsOpened?.get(`unsettledRefluxOpened_${child.id}`)!!}>
              <Textarea
                label="Details:"
                // name={`unsettledRefluxDetail_${child.id}`}
                // {...form.getInputProps(`child.${child.id}.unsettledRefluxDetail`)}
                {...form.getInputProps(`children.${index}.unsettledRefluxDetail`)}
                />
            </Collapse>

            <p>Oral assessment</p>

            <Radio.Group
              // name={`elevation_${child.id}`}
              label="Elevation"
              // withAsterisk
              my="md"
              // {...form.getInputProps(`elevation_${child.id}`)}
              // defaultValue={`${form.values.children[index].elevation}`}
              {...form.getInputProps(`children.${index}.elevation`)}
            >
              <Group mt="xs">
                <Radio value="normal" label="Normal" />
                <Radio value="restricted" label="Restricted" />
              </Group>
            </Radio.Group>

            <Radio.Group
                // name={`frenulum_${child.id}`}
                label="Frenulum"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`frenulum_${child.id}`)}
                // defaultValue={`${form.values.children[index].frenulum}`}
                {...form.getInputProps(`children.${index}.frenlum`)}
            >
              <Group mt="xs">
                <Radio value="anterior" label="Anterior" />
                <Radio value="posterior" label="Posterior" />
                <Radio value="thick" label="Thick" />
                <Radio value="thin" label="Thin" />
              </Group>
            </Radio.Group>

            <Radio.Group
                // name={`extension_${child.id}`}
                label="Extension"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`extension_${child.id}`)}
                // defaultValue={`${form.values.children[index].extension}`}
                {...form.getInputProps(`children.${index}.extension`)}
            >
              <Group mt="xs">
                <Radio value="normal" label="Normal" />
                <Radio value="restricted" label="Restricted" />
              </Group>
            </Radio.Group>



            <Radio.Group
                // name={`palate_${child.id}`}
                label="Palate"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`palate_${child.id}`)}
                // defaultValue={`${form.values.children[index].palate}`}
                {...form.getInputProps(`children.${index}.palate`)}
            >
              <Group mt="xs">
                <Radio value="normal" label="Normal" />
                <Radio value="highArched" label="High arched" />
                <Radio value="cleft" label="Cleft" />
              </Group>
            </Radio.Group>












            <Radio.Group
                // name={`previousFrenulotomy_${child.id}`}
                label="Previous frenulotomy?"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`previousFrenulotomy_${child.id}`)}
                // defaultValue={`${form.values.children[index].previousFrenulotomy}`}
                {...form.getInputProps(`children.${index}.previousFrenulotomy`)}
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>



            <Radio.Group
                // name={`reattachment_${child.id}`}
                label="Reattachment?"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`reattachment_${child.id}`)}
                // defaultValue={`${form.values.children[index].reattachment}`}
                {...form.getInputProps(`children.${index}.reattachment`)}
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>




            <Textarea
                label="Further comments on oral assessment:"
                // name={`commentsOnOralAssessment_${child.id}`}
                // {...form.getInputProps(`child.${child.id}.commentsOnOralAssessment`)}
                {...form.getInputProps(`children.${index}.commentsOnOralAssessment`)}
            ></Textarea>


          </>
        ))
        }

    </Stepper.Step>
    <Stepper.Step
      label="Mothers Current Health and History"
      description=""
      allowStepSelect={shouldAllowSelectStep(3)}
    >




<p>Mothers Current Health and History</p>



<Radio.Group
    name="motherAnaemia"
    label="Anaemia?"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.motherAnaemia')}
>
  <Group mt="xs">
    <Radio value="motherAnaemiaCurrent" label="Current" onClick={()=>updateDetailsOpened(`motherAnaemia`, true)} />
    <Radio value="motherAnaemiaHistoryic" label="Historic" onClick={()=>updateDetailsOpened(`motherAnaemia`, true)} />
    <Radio value="motherAnaemiaNo" label="No" onClick={()=>updateDetailsOpened(`motherAnaemia`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`motherAnaemia`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.motherAnaemiaDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>














<Radio.Group
    name="motherSmoker"
    label="Smoker?"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.motherSmoker')}
>
  <Group mt="xs">
    <Radio value="motherSmokerCurrent" label="Current" onClick={()=>updateDetailsOpened(`motherSmoker`, true)} />
    <Radio value="motherSmokerHistoryic" label="Historic" onClick={()=>updateDetailsOpened(`motherSmoker`, true)} />
    <Radio value="motherSmokerNo" label="No" onClick={()=>updateDetailsOpened(`motherSmoker`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`motherSmoker`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.motherSmokerDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>





<Radio.Group
    name="motherDiabetes"
    label="Diabetes?"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.motherDiabetes')}
>
  <Group mt="xs">
    <Radio value="motherDiabetesNo" label="No" onClick={()=>updateDetailsOpened(`motherDiabetes`, false)} />
    <Radio value="motherDiabetesGDM" label="GDM" onClick={()=>updateDetailsOpened(`motherDiabetes`, true)} />
    <Radio value="motherDiabetesType1" label="Type 1" onClick={()=>updateDetailsOpened(`motherDiabetes`, true)} />
    <Radio value="motherDiabetesType2" label="Type 2" onClick={()=>updateDetailsOpened(`motherDiabetes`, true)} />
    <Radio value="motherDiabetesMody" label="MODY" onClick={()=>updateDetailsOpened(`motherDiabetes`, true)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`motherDiabetes`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.motherDiabetesDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>




<Radio.Group
    name="motherMentalHealthIssues"
    label="Mental health issues"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.motherMentalHealthIssues')}
>
  <Group mt="xs">
    <Radio value="motherMentalHealthIssuesCurrent" label="Current" onClick={()=>updateDetailsOpened(`motherMentalHealthIssues`, true)} />
    <Radio value="motherMentalHealthIssuesHistoryic" label="Historic" onClick={()=>updateDetailsOpened(`motherMentalHealthIssues`, true)} />
    <Radio value="motherMentalHealthIssuesNo" label="No" onClick={()=>updateDetailsOpened(`motherMentalHealthIssues`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`motherMentalHealthIssues`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.motherMentalHealthIssuesDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>





<Radio.Group
    name="infertility"
    label="Hx of infertility or PCOS"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.infertility')}
    value={`${form.values.appointment.infertility}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>setInfertilityOpened(true)} />
    <Radio value="false" label="No" onClick={()=>setInfertilityOpened(false)} />
  </Group>
</Radio.Group>
<Collapse in={infertilityOpened}>
  <Textarea
    label="Details:"
    name="infertilityDetails"
    {...form.getInputProps('appointment.infertilityDetails')}
  />
</Collapse>


<Radio.Group
    name="otherHormonalOrEndocrineConditions"
    label="Other hormonal or endocrine conditions"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.otherHormonalOrEndocrineConditions')}
    value={`${form.values.appointment.otherHormonalOrEndocrineConditions}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>setOtherHormonalOrEndocrineConditionsOpened(true)} />
    <Radio value="false" label="No" onClick={()=>setOtherHormonalOrEndocrineConditionsOpened(false)} />
  </Group>
</Radio.Group>
<Collapse in={otherHormonalOrEndocrineConditionsOpened}>
  <Textarea
    label="Details:"
    name="otherHormonalOrEndocrineConditionsDetails"
    {...form.getInputProps('appointment.otherHormonalOrEndocrineConditionsDetails')}
  />
</Collapse>









<Radio.Group
    name="thyroid"
    label="Thyroid"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.thyroid')}
    // value={`${form.values.appointment.thyroid}`}
>
  <Group mt="xs">
    <Radio value="thyroidNo" label="No" onClick={()=>updateDetailsOpened(`thyroid`, false)} />
    <Radio value="thyroidHyper" label="Hyper" onClick={()=>updateDetailsOpened(`thyroid`, true)} />
    <Radio value="thyroidHyperGraves" label="Hyper Graves" onClick={()=>updateDetailsOpened(`thyroid`, true)} />
    <Radio value="thyroidPP" label="PP" onClick={()=>updateDetailsOpened(`thyroid`, true)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`thyroid`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.thyroidDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>




<Radio.Group
    name="allergiesInFamily"
    label="Asthma/eczema/intolerances/allergies in family"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.allergiesInFamily')}
    value={`${form.values.appointment.allergiesInFamily}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>setAllergiesInFamilyDetailsOpened(true)} />
    <Radio value="false" label="No" onClick={()=>setAllergiesInFamilyDetailsOpened(false)} />
  </Group>
</Radio.Group>
<Collapse in={allergiesInFamilyDetailsOpened}>
  <Textarea
    label="Details:"
    name="allergiesInFamilyDetails"
    {...form.getInputProps('appointment.allergiesInFamilyDetails')}
  />
</Collapse>

<Radio.Group
    name="bmi"
    label="BMI &gt;26"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.bmi')}
    value={`${form.values.appointment.bmi}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>

<Radio.Group
    name="diet"
    label="Diet"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.diet')}
>
  <Group mt="xs">
    <Radio value="dietNormal" label="Normal" />
    <Radio value="dietVegan" label="Vegan" />
    <Radio value="dietVege" label="Vege" />
    <Radio value="dietCoeliac" label="Coeliac" />
    <Radio value="dietOther" label="Other" />
  </Group>
</Radio.Group>

<Radio.Group
    name="hypertension"
    label="Hypertension or cardiac conditions"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.hypertension')}
    value={`${form.values.appointment.hypertension}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>



<Radio.Group
    name="breastSurgery"
    label="Breast/nipple surgery:"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.breastSurgery')}
>
  <Group mt="xs">
    <Radio value="breastSurgeryNo" label="No" onClick={()=>updateDetailsOpened(`breastSurgery`, false)} />
    <Radio value="breastSurgeryReduction" label="Reduction" onClick={()=>updateDetailsOpened(`breastSurgery`, true)} />
    <Radio value="breastSurgeryAugmentation" label="Augmentation" onClick={()=>updateDetailsOpened(`breastSurgery`, true)} />
    <Radio value="breastSurgeryBiopsy" label="Biopsy" onClick={()=>updateDetailsOpened(`breastSurgery`, true)} />
    <Radio value="breastSurgeryLumps" label="Lumps" onClick={()=>updateDetailsOpened(`breastSurgery`, true)} />
    <Radio value="breastSurgeryInjury" label="Injury" onClick={()=>updateDetailsOpened(`breastSurgery`, true)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`breastSurgery`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.breastSurgeryDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>



<Radio.Group
    name="radiation"
    label="Radiation or chemotherapy"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.radiation')}
    value={`${form.values.appointment.radiation}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>setRadiationOpened(true)} />
    <Radio value="false" label="No" onClick={()=>setRadiationOpened(false)} />
  </Group>
</Radio.Group>
<Collapse in={radiationOpened}>
  <Textarea
    label="Details:"
    name="radiationDetails"
    {...form.getInputProps('appointment.radiationDetails')}
  />
</Collapse>
<Radio.Group
    name="medsVitsHerbs"
    label="Current meds/vitamins/herbs"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.medsVitsHerbs')}
    value={`${form.values.appointment.medsVitsHerbs}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>setMedsVitsHerbsOpened(true)} />
    <Radio value="false" label="No" onClick={()=>setMedsVitsHerbsOpened(false)} />
  </Group>
</Radio.Group>
<Collapse in={medsVitsHerbsOpened}>
  <Textarea
    label="Details:"
    name="medsVitsHerbsDetails"
    {...form.getInputProps('appointment.medsVitsHerbsDetails')}
  />
</Collapse>


<Radio.Group
    name="feelings"
    label="Mothers feelings during BF"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.feelings')}
>
  <Group mt="xs">
    <Radio value="feelingsDMER" label="DMER" />
    <Radio value="feelingsAversion" label="Aversion" />
    <Radio value="feelingsGood" label="Good" />
  </Group>
</Radio.Group>



<Radio.Group
    name="spinalCordInjury"
    label="Spinal cord injury/accident/burn etc"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.spinalCordInjury')}
    value={`${form.values.appointment.spinalCordInjury}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>setSpinalCordInjuryOpened(true)} />
    <Radio value="false" label="No" onClick={()=>setSpinalCordInjuryOpened(false)} />
  </Group>
</Radio.Group>
<Collapse in={spinalCordInjuryOpened}>
  <Textarea
    label="Details:"
    name="spinalCordInjuryDetails"
    {...form.getInputProps('appointment.spinalCordInjuryDetails')}
  />
</Collapse>



<Radio.Group
    name=""
    label="Hyperprolactinoma"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.hyperprolactinoma')}
    value={`${form.values.appointment.hyperprolactinoma}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>setHyperprolactinomaOpened(true)} />
    <Radio value="false" label="No" onClick={()=>setHyperprolactinomaOpened(false)} />
  </Group>
</Radio.Group>
<Collapse in={hyperprolactinomaOpened}>
  <Textarea
    label="Details:"
    name="hyperprolactinomaDetails"
    {...form.getInputProps('appointment.hyperprolactinomaDetails')}
  />
</Collapse>


<Radio.Group
    name="endocrineDisruptors"
    label="Endocrine disruptors/ chem exposure"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.endocrineDisruptors')}
    value={`${form.values.appointment.endocrineDisruptors}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>setEndocrineDisruptorsOpened(true)} />
    <Radio value="false" label="No" onClick={()=>setEndocrineDisruptorsOpened(false)} />
  </Group>
</Radio.Group>
<Collapse in={endocrineDisruptorsOpened}>
  <Textarea
    label="Details:"
    name="endocrineDisruptorsDetails"
    {...form.getInputProps('appointment.endocrineDisruptorsDetails')}
  />
</Collapse>









{/*
Breast/nipple/areola complex assessment
R							L
*/}



<Radio.Group
    name="mastitis"
    label="Mastitis"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.mastitis')}
    value={`${form.values.appointment.mastitis}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`mastitis`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`mastitis`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`mastitis`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.mastitisDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>





<Radio.Group
    name="engorgement"
    label="Engorgement"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.engorgement')}
    value={`${form.values.appointment.engorgement}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`engorgement`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`engorgement`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`engorgement`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.engorgementDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>


<Radio.Group
    name="milkBlisters"
    label="Milk Blisters"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.milkBlisters')}
    value={`${form.values.appointment.milkBlisters}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`milkBlisters`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`milkBlisters`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`milkBlisters`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.milkBlistersDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>



<Radio.Group
    name="blockedDucts"
    label="Blocked ducts"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.blockedDucts')}
    value={`${form.values.appointment.blockedDucts}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`blockedDucts`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`blockedDucts`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`blockedDucts`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.blockedDuctsDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>



<Radio.Group
    name="bacterialInfection"
    label="Signs of thrush or bacterial infection"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.bacterialInfection')}
    value={`${form.values.appointment.bacterialInfection}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`bacterialInfection`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`bacterialInfection`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`bacterialInfection`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.bacterialInfectionDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>



<Radio.Group
    name="soreCrackedBleedingBruising"
    label="Sore/cracked/bleeding/bruising"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.soreCrackedBleedingBruising')}
    value={`${form.values.appointment.soreCrackedBleedingBruising}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`soreCrackedBleedingBruising`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`soreCrackedBleedingBruising`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`soreCrackedBleedingBruising`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.soreCrackedBleedingBruisingDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>


<Radio.Group
    name="nippleVasospasm"
    label="Nipple vasospasm/ Raynaud's/colour changes"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.nippleVasospasm')}
    value={`${form.values.appointment.nippleVasospasm}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`nippleVasospasm`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`nippleVasospasm`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`nippleVasospasm`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.nippleVasospasmDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>



<Radio.Group
    name="tubularBreasts"
    label="Tubular breasts / 3.8cm+ distance between / significant asymmetry /symmetrical"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.tubularBreasts')}
    value={`${form.values.appointment.tubularBreasts}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" onClick={()=>updateDetailsOpened(`tubularBreasts`, true)} />
    <Radio value="false" label="No" onClick={()=>updateDetailsOpened(`tubularBreasts`, false)} />
  </Group>
</Radio.Group>
<Collapse in={detailsOpened?.get(`tubularBreasts`)!!}>
      <Textarea
        label="Details"
        // name={`birthTypePlace_${child.id}`}
        {...form.getInputProps(`appointment.tubularBreastsDetails`)}
        // value={form.values.motherAnaemia}
      ></Textarea>
</Collapse>


</Stepper.Step>
    <Stepper.Step
      label="Breastfeeding assessment"
      description=""
      allowStepSelect={shouldAllowSelectStep(3)}
    >

<p>Breastfeeding assessment</p>






        {
        family?.children.map((child, index) => (
          <>
          {family?.children.length > 1 && <p><strong>Child #{index+1}</strong></p>}
            <p><strong>Name:</strong> {child.firstName} {child.lastName}</p>

            {/* {...form.getInputProps(`children.${index}.commentsOnOralAssessment`)} */}


            <Radio.Group
                // name={`earlyCues_${child.id}`}
                label="Using early cues"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`earlyCues_${child.id}`)}
                {...form.getInputProps(`children.${index}.earlyCues`)}
                value={`${form.values.children[index].earlyCues}`}
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>




            <Radio.Group
                name={`feedsResponsively_${child.id}`}
                label="Feeds responsively 8-12 X / 24 hrs"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`feedsResponsively_${child.id}`)}
                {...form.getInputProps(`children.${index}.feedsResponsively`)}
                value={`${form.values.children[index].feedsResponsively}`}
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>


            <Radio.Group
                name={`averageLengthOfFeeds_${child.id}`}
                label="Average length of feeds"
                // withAsterisk
                my="md"
                // {...form.getInputProps(`averageLengthOfFeeds_${child.id}`)}
                {...form.getInputProps(`children.${index}.averageLengthOfFeeds`)}
                value={`${form.values.children[index].averageLengthOfFeeds}`}
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </Radio.Group>


            <Radio.Group
    name={`rooting_${child.id}`}
    label="Rooting"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`rooting_${child.id}`)}
    {...form.getInputProps(`children.${index}.rooting`)}
    value={`${form.values.children[index].rooting}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>




















<Radio.Group
    name={`wideGape_${child.id}`}
    label="Wide gape"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`wideGape_${child.id}`)}
    {...form.getInputProps(`children.${index}.wideGape`)}
    value={`${form.values.children[index].wideGape}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





<Radio.Group
    name={`normalMer_${child.id}`}
    label="Normal MER"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`normalMer_${child.id}`)}
    {...form.getInputProps(`children.${index}.normalMer`)}
    value={`${form.values.children[index].normalMer}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>






<TextInput
label="Suck:swallow"
placeholder=':1'
// {...form.getInputProps(`suckSwallow_${child.id}`)}
{...form.getInputProps(`children.${index}.suckSwallow`)}
value={`${form.values.children[index].suckSwallow}`}
/>





<Radio.Group
    name={`babyRelaxed_${child.id}`}
    label="Baby relaxed during feed"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`babyRelaxed_${child.id}`)}
    {...form.getInputProps(`children.${index}.babyRelaxed`)}
    value={`${form.values.children[index].babyRelaxed}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





<Radio.Group
    name={`secondBreastOffered_${child.id}`}
    label="Second breast offered"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`secondBreastOffered_${child.id}`)}
    {...form.getInputProps(`children.${index}.secondBreastOffered`)}
    value={`${form.values.children[index].secondBreastOffered}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





<Radio.Group
    name={`comfortableForMum_${child.id}`}
    label="Comfortable for mum"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`comfortableForMum_${child.id}`)}
    {...form.getInputProps(`children.${index}.comfortableForMum`)}
    value={`${form.values.children[index].comfortableForMum}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





<Radio.Group
    name={`facingBreastAligned_${child.id}`}
    label="Facing breast/aligned"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`facingBreastAligned_${child.id}`)}
    {...form.getInputProps(`children.${index}.facingBreastAligned`)}
    value={`${form.values.children[index].facingBreastAligned}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





<Radio.Group
    name={`asymmetricDeepLatch_${child.id}`}
    label="Asymmetric deep latch"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`asymmetricDeepLatch_${child.id}`)}
    {...form.getInputProps(`children.${index}.asymmetricDeepLatch`)}
    value={`${form.values.children[index].asymmetricDeepLatch}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





<Radio.Group
    name={`comesOffAfterFeed_${child.id}`}
    label="Comes off after feed"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`comesOffAfterFeed_${child.id}`)}
    {...form.getInputProps(`children.${index}.comesOffAfterFeed`)}
    value={`${form.values.children[index].comesOffAfterFeed}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





<Radio.Group
    name={`nippleToNose_${child.id}`}
    label="Nipple to nose + chin to breast"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`nippleToNose_${child.id}`)}
    {...form.getInputProps(`children.${index}.nippleToNose`)}
    value={`${form.values.children[index].nippleToNose}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





<Radio.Group
    name={`closeContactWithBody_${child.id}`}
    label="Close contact with mums' body"
    // withAsterisk
    my="md"
    // {...form.getInputProps(`closeContactWithBody_${child.id}`)}
    {...form.getInputProps(`children.${index}.closeContactWithBody`)}
    value={`${form.values.children[index].closeContactWithBody}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>





        </>
        ))}















<Radio.Group
    name="nippleNormalColour"
    label="Nipple normal shape/colour on release"
    // withAsterisk
    my="md"
    {...form.getInputProps('nippleNormalColour')}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>













<Textarea
  label="Support/discussion during consultation and suggested feeding plan and follow up"
  {...form.getInputProps('appointment.supportDiscussion')}
/>




</Stepper.Step>
    <Stepper.Step
      label="Follow up"
      description=""
      allowStepSelect={shouldAllowSelectStep(4)}
    >





<RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>


      <RichTextEditor.Content mih={400}/>
    </RichTextEditor>
    <MultiSelect
      label="Select sections to pre-fill:"
      placeholder="Pick value"
      data={data}
      maxDropdownHeight={200}
      onChange={categoryChanged}
      value={selectedCats}
    />
    <Button
      onClick={()=>addSection()}
    >Add section(s)</Button>




<Textarea mb="lg"
  label="Follow Up Appointment:"
  name="followUpAppointment"
  {...form.getInputProps('appointment.followUpAppointment')}
/>



    </Stepper.Step>








    <Stepper.Completed>



      <Radio.Group
    name="pumpLoan"
    label="Pump loan"
    // withAsterisk
    my="md"
    {...form.getInputProps('appointment.pumpLoan')}
    value={`${form.values.appointment.pumpLoan}`}
>
  <Group mt="xs">
    <Radio value="true" label="Yes" />
    <Radio value="false" label="No" />
  </Group>
</Radio.Group>







<Textarea mb="lg"
  label="Suggestions:"
  name="suggestions"
  {...form.getInputProps('appointment.suggestions')}
/>


      <p>Please press the button below to send us the information.</p>
        <Button type="submit" size="md" loading={submitLoader}>
        Save consultation form
      </Button>

    </Stepper.Completed>
  </Stepper>
  <Group position="center" mt="xl" mb="md">
    <Button variant="default" onClick={() => handleStepChange(active - 1)}>Back</Button>
    <Button onClick={() => handleStepChange(active + 1)}>Next step</Button>
  </Group>






{/*
<p><button onClick={
// https://developer.chrome.com/docs/workbox/modules/workbox-background-sync/
  (event) => {
    console.log('test');

    API.post('/consult',{test:'test'},cookieToken).then(
    	()=>{console.log('sent')},
    )

  }
}>queue sync test</button></p>

<p><button onClick={
  (event) => {
    console.log('sync test #1');

    navigator.serviceWorker.ready.then(function(swRegistration) {
      console.log('sync test #2', swRegistration);
      return swRegistration.sync.register('workbox-background-sync:consultQueue');
    });

    // navigator.permissions.query({ name: "periodic-background-sync" }).then((status)=>{ console.log(status)})
  }
}>Sync data</button></p>
*/}















{/*
<Button type="submit" size="md">
          Save
        </Button>
*/}
        </form>



        </Box>
    )

    return (
      <>
      {showPage &&
        <AdminNav currentPage="Families" content={pageContent} />
      }
      </>
    );
}
