import AdminNav from '../../components/AdminNav';

import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { API } from '../../classes/API';
import { Button, Grid, MultiSelect, SimpleGrid, rem } from '@mantine/core';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useForm } from '@mantine/form';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import {
  // useSearchParams,
  useLoaderData,
  useParams,
} from "react-router-dom";



export default function FollowUpForm() {
  const [cookies, setCookie] = useCookies(['token']);
  const [showPage, setShowPage] = useState(false);
  const [ selectedCats, setSelectedCats ] = useState([]);

  useEffect(
    () => {
      //Runs only on the first render
      const cookieToken = cookies.token
        API.get('/reauth', cookieToken).then(
        (response) =>  {
          if (response.status === 401) {
            setCookie("token", "")
            window.location.replace('/admin/login');
            return <></>
          } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
            setShowPage(true)
          }
        },
        error => {
            console.log('ERROR?!?',error)
        }
      );

      /*
      API.get('/email',cookieToken).then((response) =>  {
          const jsonData = response.body;
          console.log('got family data',jsonData)
          setEmails(jsonData);
      });
      if (cookieToken === '') {
          window.location.replace('/admin/login');
          return
      }
      */
    },
    []
  );

  const sectionNames = [
    'Tongue Tie',
    'Milk Transfer',
    'Breast Compressions',
    'Pumping',
    'Positioning and Attachment',
    'Koala Hold',
    'Exaggerated latch',
    'Periodontal syringe',
    'Finger feeding with tube',
    'Vitamin D',
    'Switch nursing',
    'Paced bottle feeding',
    'Nipple shields',
    'Skin to skin',
    'Blockages/mastitis',
    'SNS',
    'Reasons for low supply',
    'PCOS info',
    'Donor milk',
    'Galactagogues',
    'Blood tests',
    'Coming off shield',
    'Feeding cues:',
    'Feeding cues newborn:',
    'Dropping top ups safely:',
    'Triple feeding suggestion:',
    'Treatment for sore nipples:',
    'Supplements via bottle:',
    'Elevated side lying:',
    'Slow flow teat:',
    'Laid back position:',
    'Laid back position with large breasts:',
    'Breastfeeding with large breasts:',
    'Info on Dummies:',
    'Osteo:',
    'Floppy larynx:',
    'Safe sleep:',
    'Nappy output:',
    'Cafes:',
    'Domperidone',
  ]

  const sectionContent = [
    "With your consent, I assessed ((Baby name))’s tongue function to see if there is a restriction in tongue movement that could be causing a feeding issue. The palate appeared ((normal)). The tongue appeared to move well to the ((right and left)). He ((can/cannot)) extend his tongue past his gum ridge and does/does not elevate the tongue well. Due to the ((lateral movement to the right)) being restricted, and other symptoms including ((weight gain issue)), we decided I would refer ((Baby name)) for an appointment with the tongue tie team at the James Paget University Hospital. You will hear from them regarding an appointment. Your patient information leaflet on what to expect is here: https://www.jpaget.nhs.uk/media/468075/CH-76-Restrictive-Lingual-Frenulum-Tongue-tie-Trust-print-v1-web.pdf",
    "These two videos are great for understanding how to see if a baby is drinking well at the breast. How do I know my baby is getting enough milk? - YouTube and How to recognise when a baby is drinking well - YouTube",
    "Use breast compressions to increase effectiveness of ((baby name))’s time at the breast. You don't need to use them whilst their suck to swallow ratio is 1:1 as you can’t get more effective than that! But they are helpful when they are swallowing less frequently https://breastfeeding.support/what-is-breast-compression/",
    "Here is our pumping guide: Expressing breastmilk | My Site (lowestoftandwaveneybreastfeeding.co.uk).  Pumping 8-12  times per day will help improve your milk supply. We also have a guide to using your breast pump: Breast Pump Guide | My Site (lowestoftandwaveneybreastfeeding.co.uk). It includes information on sterilising and breast milk storage.",
    "Tips for getting a deep latch are here Positioning and attachment | My Site (lowestoftandwaveneybreastfeeding.co.uk) . Make sure you latch baby with their head tipped back and clear so that their chin is doing all the work.",
    "Video of Koala Hold: https://youtu.be/puw8Hy0wMiM?feature=shared",
    "This is an excellent video on a technique called the ‘exaggerated latch’ which I suggest you try. This helps get a deeper latch. https://www.youtube.com/watch?v=41fC0fQs1P8",
    "You can use a periodontal syringe to finger feed (baby’s name) your breast milk. You can also use this to supplement at the breast. How-To guide and safety precautions here: Peridontal Syringe use | My Site (lowestoftandwaveneybreastfeeding.co.uk)",
    "Finger feeding with a tube device can help teach babies to suck more actively. How-to videos here: Finger Feeding to Supplement a Breastfeeding Infant - YouTube and (229) Syringe Feeding your Baby - YouTube.",
    "Vitamin D guidelines for babies Baby Vitamins | Baby | Start for Life (www.nhs.uk).",
    "If (baby’s name) gets sleepy or frustrated on one breast then switch them to the other. You can go back and forth between breasts as frequently as you need to keep the feed active. Watch for (baby’s name) to stop actively sucking and swallowing. If breast compressions don’t get (baby’s name) to start feeding again, swap to the other breast. You may find initially you have to repeat this very frequently. As (baby’s name) becomes less sleepy/frustrated at the breast then things will improve.",
    "Offer a ((volume)) of ((expressed milk/formula)) after breastfeeds to support (baby’s name) to gain weight. Use paced bottle feeding as this is safer and healthier for babies. Please see: https://www.midyorks.nhs.uk/responsive-bottle-feeding/",
    "Here is some guidance on how to use a nipple shield: https://www.lowestoftandwaveneybreastfeeding.co.uk/nipple-shields",
    "Having skin to skin contact whenever possible can really help with feeding, milk supply, bonding and baby’s brain development",
    "Information on blocked ducts/mastitis: Blocked ducts, Mastitis, Abscess. Blebs. | My Site (lowestoftandwaveneybreastfeeding.co.uk).",
    "DIY at-breast supplementer can be a great way to give baby their extra milk. Tips for using the supplementer here DIY Supplemental nursing system | My Site (lowestoftandwaveneybreastfeeding.co.uk)",
    "We discussed your reduced milk supply. I am not able to diagnose a reason behind it, but I can offer possible explanations that may account for it. We discussed how it could be due to a combination of factors, including (reduced breast stimulation/breast surgery/breast injury/stressful time in hospital/your diagnosis of PCOS).\n\nThis article outlines further reasons for reduce milk supply: https://breastfeeding.support/reasons-low-milk-supply/",
    "This is an excellent article, explaining PCOS and its potential impact on milk supply: https://breastfeeding.support/polycystic-ovary-syndrome-breastfeeding/ It has very good links within it.\n\nAlthough PCOS can impact milk supply, this isn't the case for all mothers -supply very much varies from woman to woman. We discussed plans to give your milk supply a good chance to increase and how breastfeeding doesn't have to be ‘all or nothing’ - any breast milk, whether for days, months or years, is valuable to you and your baby. Feeding the babies at the breast via an at-breast supplementer with formula is also an option: Tips for using the supplementer here DIY Supplemental nursing system | My Site (lowestoftandwaveneybreastfeeding.co.uk)",
    "You could consider the use of donor breast milk for ((baby name)) - here is more information https://humanmilkfoundation.org/hearts-milk-bank/need-support/  If you would like to apply for this you would need to contact the Hearts Milk Bank to request this, and I would email them to back-up your request.",
    "You could consider taking a Galactagogue (medications/herbs/foods thought to increase supply) to help increase your supply. Here is some information so that you can make an informed choice. Please check with your GP before taking any galactogogue to ensure it will be suitable for you and any current medications/conditions: https://breastfeeding.support/what-is-a-galactagogue/ and https://ibconline.ca/information-sheets/herbal-remedies-for-milk-supply/",
    "We can request that your GP check your blood levels to see if your thyroid, iron levels, hormone levels etc are influencing your milk supply. If you decided you would like this, I can write to your GP to back up your request.",
    "Tips for coming off nipple shields: https://breastfeeding.support/weaning-from-a-nipple-shield/",
    "Watch for feeding cues and feed when ((Baby name)) shows these.",
    "Whilst establishing feeding, and getting back up to birth weight, if baby doesn’t show cues by 3 hrs since the beginning of last feed then I suggest waking them to feed to ensure a bare minimum of 8 feeds per 24hrs. At night feed by 4 hours. 8-12 feeds per day is a typical range of feeds for a newborn - with 11-12 being the average.",
    "As milk transfer at the breast improves, and we are confident her weight gain is appropriate, then ‘top up’ feeds can reduce gradually. We have a guide on how to do this safely: https://www.lowestoftandwaveneybreastfeeding.co.uk/dropping-top-ups",
    "Limit the feed/top up/pump process to a maximum of one hour so that it is sustainable for you. This may mean limiting the breastfeed to around 30 minutes as a temporary measure whilst working on supply.",
    "https://breastfeeding.support/treatments-sore-nipples/",
    "Offer ((volume)) of ((expressed milk/formula)) after breastfeeds.",
    "An alternative technique is ‘Elevated side lying’, used to feed a baby struggling with paced bottle feeding bottle feeding https://www.feedeatspeak.co.uk/blog/2018/8/14/dummy-blog#:~:text=This%20is%20a%20great%20position,them%20to%20vomit%20or%20reflux",
    "No matter the age of the baby, ensure the bottle has a slow flow teat.",
    "I suggest trying the laid back position/natural breastfeeding: https://www.youtube.com/watch?v=ZJan8xCNgY4",
    "Here’s a great video on the laid back position with large breasts: https://www.youtube.com/watch?v=584nv1oNxvw",
    "Guidance on breastfeeding with large breasts:\nhttps://breastfeeding.support/breastfeeding-with-large-breasts/",
    "Information on dummy use: https://www.basisonline.org.uk/dummies/",
    "Some babies experience bodily tensions from pregnancy and birth and some families find their baby benefits from Cranial Osteopathy. Here is some more information: https://breastfeeding.support/cranial-osteopathy-or-craniosacral-therapy/ If this is something you would like to pursue, here is the link to finding a local practitioner: https://www.osteopathy.org.uk/register-search/practices/?location=Norfolk&radius=5& surn ame=®istration= It is important to check they are trained to treat babies",
    "((baby name)) makes a squeaking noise when breathing. This happens at all times/only during feed. You report this has been since birth. Sometimes this noise can be due to a floppy larynx however I am not a medical professional therefore cannot diagnose - I suggested discussing this with your GP.",
    "Information on safe sleep: https://www.unicef.org.uk/babyfriendly/wp-content/uploads/sites/2/2018/08/Caring-for-your-baby-at-night-web.pdf    There is an App that helps provides you with a ‘bed-sharing decision guide’ so you can make an informed decision about ((baby’s name))’s sleep space: https://www.basisonline.org.uk/infant-sleep-info-app/",
    "Monitor nappy output - any concerns, contact your midwife/Health Visitor/GP. https://www.lowestoftandwaveneybreastfeeding.co.uk/nappies",
    "Consider attending one of our feeding cafes: https://www.lowestoftandwaveneybreastfeeding.co.uk/breastfeeding-cafes",
    "We discussed the option of contacting your GP for medication that may increase your milk supply (Domperidone). Here is some more detailed information Domperidone and breastfeeding - The Breastfeeding Network. If you would like to ask your GP about a domperidone prescription I can write a letter supporting this, please let me know if you’re interested.",
  ]

  const categoryChanged = (val:any) => {
    setSelectedCats(val);
  }


  const addSection = () => {
    selectedCats.map((cat) => {
      for (const k in sectionNames) {
        const v = sectionNames[k]
        if (v == cat) {
          editor?.commands.insertContent('<p><strong>'+cat+'</strong></p><p>'+sectionContent[k]+'</p>')
          continue
        }
      }
    })
    setSelectedCats([])
    if (editor instanceof Editor) {
      form.values.body = editor.getText()
      form.values.bodyHTML = editor.getHTML()
    }
    return
  }



  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      body: '',
      bodyHTML: '',
    },

});


const editor = useEditor({
  extensions: [
    StarterKit,
    Link,
  ],
  onBlur:(e)=>{
    console.log('blur',e,e.editor.getText())
    form.values.body = e.editor.getText()
    form.values.bodyHTML = e.editor.getHTML()
}
});



useEffect(() => {
  //Runs only on the first render
  setToken(cookies.token)


  const cookieToken = cookies.token

  API.get('/reauth', cookieToken).then(
      (response) =>  {
          if (response.status === 401) {
              setCookie("token", "")
              window.location.replace('/admin/login');
              return <></>
          } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
              setShowPage(true)
          }
      },
      error => {
          console.log('ERROR?!?',error)
      }
  );

  if (cookieToken === '') {
      window.location.replace('/admin/login');
      return //<></>
  }

  if (params["familyId"]) {
      editor?.commands.setContent('')

      // setIsNew(false)
      // setButtonText("Update User")
      API.get('/family/'+params["familyId"]+'/followup', cookieToken).then(
          (data) => {
              console.log('got data', data, data.body)
              if (data.status == 200) {
                  form.setValues({
                      // id: data.body.id,
                      // name: data.body.name,
                      // subject: data.body.subject,
                    })
                    form.values.body = data.body.body
                    form.values.bodyHTML = data.body.bodyHTML

                  console.log('active', editor);
                    editor?.commands.setContent(data.body.bodyHTML)
              }
          }
      )
  }

}, [editor]);





  const data = Object.values(sectionNames)
  const params = useParams();
  const [token, setToken] = useState("");

  const pageData = <>
    <h1>Follow up Email</h1>


    <form onSubmit={form.onSubmit(
                (values, _event) => {
                  console.log('vals',values)
                    notifications.show({
                        id: 'updateEmail',
                        message: 'Saving',
                        loading:true,
                        autoClose: false,
                      })

                      // values.body



                        API.put('/family/'+params["familyId"]+'/followup',values,token).then(
                        // API.put('/test',values,token).then(
                            (response) => {
                                // if (response.body.id) {
                                    // window.location.replace('/admin/emails');
                                // }
                                notifications.update({
                                    id:'updateEmail',
                                    color: 'teal',
                                    message: 'Email was updated',
                                    icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                                    loading: false,
                                    autoClose: 2000,
                                  });

                            },
                            (e) => {
                                notifications.update({
                                    id:'updateEmail',
                                    color: 'red',
                                    message: 'Error updating the email',
                                    icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                                    loading: false,
                                    autoClose: 2000,
                                  });

                            }
                        )
                },
                (validationErrors, _values, _event) => {
                    console.log('ERRORS',validationErrors)
                    console.log('FORM VALS',_values)
                }
            )}>








    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>


      <RichTextEditor.Content mih={400}/>
    </RichTextEditor>
    <MultiSelect
      label="Select sections to pre-fill:"
      placeholder="Pick value"
      data={data}
      maxDropdownHeight={200}
      onChange={categoryChanged}
      value={selectedCats}
    />
    <Button
      onClick={()=>addSection()}
    >Add section(s)</Button>
    <p>
    <Button
      type="submit"
    >Save</Button>

    </p>
    </form>
  </>

  return (
    <>
      {showPage &&
      <AdminNav currentPage="Family" content={pageData} />
      }
    </>
  );
}
