// https://theroadtoenterprise.com/blog/how-to-download-csv-and-json-files-in-react


import AdminNav from '../../components/AdminNav';
import CsvDownloader from 'react-csv-downloader';
import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  ScrollArea,
  useMantineTheme,
  Tooltip,
  Button,
  Select,
  Modal,
  Grid,
} from '@mantine/core';
import { IconPencil, IconTrash, IconFileText, IconEye, IconStethoscope } from '@tabler/icons-react';

import { API } from '../../classes/API';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDisclosure } from '@mantine/hooks';

import {
  // useSearchParams,
  useLoaderData,
  useParams,
} from "react-router-dom";


type csvProps = {
    parentFirstName: string,
    parentLastName: string,
    // babyFirstName: string,
    // babyLastName: string,
    email: string,
};

type selectProps = {
  value: string,
  label: string,
};

export default function FamilyList() {
    const [families, setFamilies] = useState<familyProps[]>([]);
    const [familiesCSV, setFamiliesCSV] = useState<csvProps[]>([]);
    // const [token, setToken] = useState('');
    const [cookies, setCookie] = useCookies(['token']);
    const [users, setUsers] = useState<selectProps[]>([])
    const [showPage, setShowPage] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [modalContent, setModalContent] = useState(<></>);

    const [opened, { open, close }] = useDisclosure(false);

    const params = useParams();
    console.log('params?',params)

    useEffect(
        () => {
            //Runs only on the first render
            const cookieToken = cookies.token
                API.get('/reauth', cookieToken).then(
                (response) =>  {
                    if (response.status === 401) {
                        setCookie("token", "")
                        window.location.replace('/admin/login');
                        return <></>
                    } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
                        setShowPage(true)
                    }
                },
                error => {
                    console.log('ERROR?!?',error)
                }
            );


            let apiUrl = '/family'
            if (params['userId'] != undefined) {
              apiUrl += '/user/' + params['userId']
            }

            API.get(apiUrl,cookieToken).then((response) =>  {
                const jsonData = response.body;
                console.log('status',response)
                if (response.status == 200) {
                  console.log('got family data',jsonData)
                  setFamilies(jsonData);
                }
            });
            API.get('/user',cookieToken).then((response) =>  {
              const jsonData = response.body;
              console.log('got user data',jsonData)

              if (response.status == 200) {
                const userData:selectProps[] = [{value:'',label:'Show all families'}]

                jsonData.forEach((element:userProps) => {
                  userData.push({value:element.id.toString(), label: `${element.firstName} ${element.lastName}`})
                });

                setUsers(userData);
              }
          });
          if (cookieToken === '') {
                window.location.replace('/admin/login');
                return
            }
        },
        []
    );



    const columns = [
      {
        id: 'parentFirstName',
        displayName: 'First Name'
      },
      {
        id: 'parentLastName',
        displayName: 'Last Name'
      },
      {
        id: 'parentPronoun',
        displayName: 'Pronoun'
      },
      {
        id: 'dateOfBirth',
        displayName: 'Date of Birth'
      },
      {
        id: 'address1',
        displayName: 'Address 1'
      },
      {
        id: 'address2',
        displayName: 'Address 2'
      },
      {
        id: 'city',
        displayName: 'City'
      },
      {
        id: 'county',
        displayName: 'County'
      },
      {
        id: 'postcode',
        displayName: 'Postcode'
      },
      {
        id: 'email',
        displayName: 'Email'
      },
      {
        id: 'phoneNumber',
        displayName: 'Phone Number'
      },
      {
        id: 'user',
        displayName: 'Assigned User'
      },
      {
        id: 'namedHealthVisitor',
        displayName: 'Named Health Visitor'
      },
      {
        id: 'namedMidwife',
        displayName: 'Named Midwife'
      },

    ];

    const generateCSV = () => {
      const cookieToken = cookies.token
      API.get('/csv/family',cookieToken).then((response) =>  {
        const jsonData = response.body;
        console.log('got family CSV data',jsonData)
        setFamiliesCSV(jsonData);
        // return Promise.resolve(jsonData)
    });

    }


    // const asyncFnComputeDate = () => {
    //   // do whatever you need async
    //   const cookieToken = cookies.token
    //   API.get('/csv/family',cookieToken).then((response) =>  {
    //     const jsonData = response.body;
    //     console.log('got family CSV data',jsonData)
    //     // setFamilies(jsonData);
    //     return Promise.resolve(jsonData)
    // });

    //   return Promise.resolve([{
    //     first1: 'foo',
    //     second1: 'bar'
    //   }, {
    //     first1: 'foobar',
    //     second1: 'foobar'
    //   }]);

    // };



const userFilter = (
  <>
  {
    users.length == 0 ? (
      <p>
        loading users
      </p>
    ) : (
      <p>
        <Select
          label="Filter by user"
          placeholder="Select a user"
          data={users}
          onChange={(userId)=>{
            console.log('changed',userId)
            if (userId == '') {
              window.location.replace('/admin/families');
            } else {
              window.location.replace('/admin/families/user/' + userId);
            }
          }}
        />
      </p>
    )
  }
    </>


)


const del = (index:number) => {
  console.log('del',index, families[index])
  setDeleteId(index)

  setModalContent(
    <>
<p>Please confirm you want to delete the family of {families[index].parentFirstName} {families[index].parentLastName}</p>
<Grid justify="space-between" align="stretch">
<Button onClick={close}>Cancel</Button>
<Button color='red' onClick={()=>{
          API.delete(`/family/${families[index].id}`,cookies.token).then(

            (response) =>  {
              console.log(response)
                if (response.status === 200) {
                  // Reload after deleting
                    //window.location.replace('/admin/families');
                    window.location.reload();
                    // return <></>
                }
            },
            error => {
                console.log('ERROR?!?',error)
            }

        )
        }}>Delete</Button>
        </Grid>

    </>
  )
  open()
}

    const theme = useMantineTheme();
    const familyTable = (
        <>
            <h1>Families</h1>
            <ScrollArea>
              <p>{userFilter}</p>
                <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
                    <thead>
                        <tr>
                            <th>Parent Name</th>
                            <th>Baby Name</th>
                            <th>Email</th>
                            <th>Assigned User</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            families.map((family, index) => (
                                <tr key={family.id}>
                                    <td>
                                        <Text fz="sm">
                                            {family.parentFirstName} {family.parentLastName}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text fz="sm">
                                        {
                            family.children.map((child, i) => (
                                <>
                                {(i > 0) && <br/>}
                                {child.firstName} {child.lastName}
                                </>
                            ))}
                                        </Text>
                                    </td>
                                    <td>
                                        <Anchor component="button" size="sm">
                                            {family.email}
                                        </Anchor>
                                    </td>
                                    <td>{family.user != null && `${family.user.firstName} ${family.user.lastName}`}</td>
                                    <td>
                                        <Group spacing={0} position="right">
                                          {(family.appointment != null) && (
                                            <>
                                            <a href={'/admin/family/' + family.id + '/consult'}>
                                                <Tooltip
                                                label="Consult form"
                                                color="blue"
                                                withArrow
                                                >

                                            <ActionIcon>
                                                <IconFileText size="1rem" stroke={1.5} />
                                            </ActionIcon>
                                            </Tooltip>
                                            </a>

                                            <a href={'/admin/family/' + family.id + '/gp'}>
                                                <Tooltip
                                                label="GP Email"
                                                color="blue"
                                                withArrow
                                                >

                                            <ActionIcon>
                                                <IconStethoscope size="1rem" stroke={1.5} />
                                            </ActionIcon>
                                            </Tooltip>
                                            </a>
                                            </>
                                    )
}


                                    <a href={'/admin/family/' + family.id}>
                                    <Tooltip
                                                label="View"
                                                color="blue"
                                                withArrow
                                                >
                                            <ActionIcon>
                                                <IconEye size="1rem" stroke={1.5} />
                                            </ActionIcon>
                                            </Tooltip>
                                            </a>
                                            <Tooltip
                                                label="Delete"
                                                color="red"
                                                withArrow
                                                >

                                            <ActionIcon color="red" onClick={()=>{
                                              del(index)
                                            }}>
                                                <IconTrash size="1rem" stroke={1.5} />
                                            </ActionIcon>
                                            </Tooltip>
                                        </Group>
                                    </td>
                                </tr>

                            ))
                        }
                    </tbody>
                </Table>


                {
                  familiesCSV.length == 0 ? (
                    <p>
                      <Button onClick={generateCSV}>Generate CSV</Button>
                    </p>
                  ) : (
                    <p>
                      <CsvDownloader
                        filename="myfile"
                        extension=".csv"
                        separator=";"
                        wrapColumnChar="'"
                        columns={columns}
                        datas={familiesCSV}
                      >
                        <Button>Download CSV</Button>
                      </CsvDownloader>
                    </p>
                  )
                }
            </ScrollArea>

            <Modal opened={opened} onClose={close} title="Delete family?" centered>
           {modalContent}
           </Modal>
        </>
    );

    return (
      <>
      {showPage &&
        <AdminNav currentPage="Families" content={familyTable} />
      }
      </>
    );
  }
