import AdminNav from '../../components/AdminNav';

import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { API } from '../../classes/API';
import { Button, Grid, MultiSelect, SimpleGrid, rem } from '@mantine/core';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'

import { useForm } from '@mantine/form';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import {
  // useSearchParams,
  useLoaderData,
  useParams,
} from "react-router-dom";



export default function GPForm() {
  const [cookies, setCookie] = useCookies(['token']);
  const [showPage, setShowPage] = useState(false);

  useEffect(
    () => {
      //Runs only on the first render
      const cookieToken = cookies.token
        API.get('/reauth', cookieToken).then(
        (response) =>  {
          if (response.status === 401) {
            setCookie("token", "")
            window.location.replace('/admin/login');
            return <></>
          } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
            setShowPage(true)
          }
        },
        error => {
            console.log('ERROR?!?',error)
        }
      );

      /*
      API.get('/email',cookieToken).then((response) =>  {
          const jsonData = response.body;
          console.log('got family data',jsonData)
          setEmails(jsonData);
      });
      if (cookieToken === '') {
          window.location.replace('/admin/login');
          return
      }
      */
    },
    []
  );


  const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
      body: '',
      bodyHTML: '',
    },

});


const editor = useEditor({
  extensions: [
    StarterKit,
    Link,
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TableHeader,
    TableCell,
  ],
  onBlur:(e)=>{
    console.log('blur',e,e.editor.getText())
    form.values.body = e.editor.getText()
    form.values.bodyHTML = e.editor.getHTML()
}
});



useEffect(() => {
  //Runs only on the first render
  setToken(cookies.token)


  const cookieToken = cookies.token

  API.get('/reauth', cookieToken).then(
      (response) =>  {
          if (response.status === 401) {
              setCookie("token", "")
              window.location.replace('/admin/login');
              return <></>
          } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
              setShowPage(true)
          }
      },
      error => {
          console.log('ERROR?!?',error)
      }
  );

  if (cookieToken === '') {
      window.location.replace('/admin/login');
      return //<></>
  }

  if (params["familyId"]) {
      editor?.commands.setContent('')

      // setIsNew(false)
      // setButtonText("Update User")





      API.get('/family/'+params["familyId"]+'/gp', cookieToken).then(
          (data) => {
              console.log('got data', data, data.body)
              if (data.status == 200) {
                  form.setValues({
                      // id: data.body.id,
                      // name: data.body.name,
                      // subject: data.body.subject,
                    })
                    form.values.body = data.body.body
                    form.values.bodyHTML = data.body.bodyHTML

                  console.log('active', editor);
                  console.log('setting content to ',data.body.bodyHTML)
                    editor?.commands.setContent(data.body.bodyHTML)
              } else if (data.status == 204) {
                // Missing data, let's inject it into the editor
                console.log('create new data')



      // form.values.body = 'test'
      form.values.bodyHTML = `
      <p>To Whom it may concern,</p>
      <p>I am an International Board Certified Lactation Consultant (IBCLC). I have been supporting XX and her XX with feeding and I am writing to let you know of the support I have been giving them and to request some blood tests and investigations into XX low milk supply. I have the consent of XX to share this with you.</p>
      <p>I met with XX on XX  after she had requested a home visit. We spent two hours discussing XX feeding journey over the past few weeks with XX. XX appears to have a low milk supply despite breastfeeding and pumping frequently. </p>
      <p>It would be ideal to have the following tests to see if there is any reason behind XX low milk supply.   </p>
      <ul>
        <li>Thyroid function</li>
        <li>Full blood count</li>
        <li>Cholesterol</li>
        <li>Hba1c (or fasting glucose)</li>
        <li>Testosterone (FAI and free)</li>
        <li>Prolactin</li>
      </ul>
      <p>*Information on Prolactin levels in lactating parents *- Information taken from “Making More Milk: The Breastfeeding Guide to Increasing Your Milk Production - Marasco L. and West D (2020)”</p>
      <p>Measuring prolactin levels will depend on the age of the baby and the frequency of nursing. Levels are highest around birth and then decline over the next few months to a lower plateau. Baseline measurements should be drawn where there's been no nipple stimulation for 90-120 minutes and then reactive prolactin, the surge response for sucking stimulation by taking blood 10-15 minutes after the end of a 20-30 minutes breastfeed or 15 minute double pumping session. If only one blood test can be taken the surge is a better option to take as you can see how much prolactin is released before the body starts to process it.</p>
      <p>Standard lab reports typically list the ranges for nonpregnant, non-lactating females and many females have been told their baseline prolactin is “normal” when in fact it is quite low for lactation. Baseline or surge, the following chart gives you an approximate idea of what to expect either way for lactation.</p>

      <br />
      <p><strong>Approximate Prolactin Levels for Exclusively Breastfeeding</strong></p>
        <table>
          <tbody>
            <tr>
              <th></th>
              <th colspan="2">Baseline</th>
              <th colspan="2">Level after suckling</th>
            </tr>
            <tr>
              <th>Stage</th>
              <th>ng/ml</th>
              <th>mlU/L</th>
              <th>ng/ml</th>
              <th>mlU/L</th>
            </tr>
            <tr>
              <td>Female menstrual life</td>
              <td>2-20</td>
              <td>22-225</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>3rd trimester of pregnancy</td>
              <td>100-550</td>
              <td>2128-11700</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Term pregnancy</td>
              <td>200-600</td>
              <td>4255-12766</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>First 10 days postpartum</td>
              <td>200</td>
              <td>4255</td>
              <td>400</td>
              <td>8510</td>
            </tr>
            <tr>
              <td>1 month</td>
              <td>100-140</td>
              <td>2128-2978</td>
              <td>260-310</td>
              <td>5532-6596</td>
            </tr>
            <tr>
              <td>2 months</td>
              <td>100-140</td>
              <td>2128-2978</td>
              <td>195-240</td>
              <td>4149-5106</td>
            </tr>
            <tr>
              <td>4 months</td>
              <td>60-80</td>
              <td>1277-1702</td>
              <td>120-155</td>
              <td>2553-3298</td>
            </tr>
            <tr>
              <td>6 months</td>
              <td>50-65</td>
              <td>1063-1383</td>
              <td>80-100</td>
              <td>1702-2128</td>
            </tr>
            <tr>
              <td>7 months to 1 year</td>
              <td>30-40</td>
              <td>638-851</td>
              <td>45-80</td>
              <td>957-1702</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Collated from:</strong><br>
          Cox D, Owens R, Hartmann P. Blood and milk prolactin and the rate of milk synthesis in women. Exp Physol. 1996:81(6):1007-1020.<br>
          Lawrence RA, Lawrence RM. Breastfeeding: A Guide for the Medical Profession. 9th ed. Philadelphia: Elsevier, 2016:65<br />
          López MÁC, Rodriguez JLR, Garcia MR. Physiological and pathological hyperprolactinemia: can we minimize errors in the clinical practice? In Prolactin: InTech; 2013. https://www.intechopen.com/books/prolactin/physiological-and-pathological-hyperprolactinemia-can-we-minimize-errors-in-the-clinical-practice-. doi: 10.5772/54758
        </p>
        <br />


        <p>My recommendations to XX are to continue to breastfeed where possible while giving XX the supplementation he requires to grow. I will continue to support XX and XX with their feeding for as long as they wish me to.</p>
        <p>XX wishes to try the galactagogue (drug to increase milk supply) Domperidone, which has the best evidence base out of all the galactagogues. Because it is a Dopamine antagonist, it stimulates prolactin release. Dopamine has some association with cardiac issues in patients with cardiac histories/problems in later life, but XX has had no cardiac issues so is low risk category for this medication. The prescription is Domperidone 10mg TDS. The current evidence-based information for this is here - https://www.breastfeedingnetwork.org.uk/domperidone/. </p>
        <p>My recommendations to XX are to continue to breastfeed and express, followed by giving XX the supplementation he requires to grow, at present she has about XX% expressed breast milk and XX% formula. XX has been trying to increase her supply for the last two months and has tried all our suggestions. I will continue to support them with their feeding for as long as they wish me to. If you have any concerns about any of the above, please let me know. The best way to contact me is by email: lowestoftandwaveney@hotmail.com</p>
        <p>Thank you for your time in reading this - I hope it has been helpful to hear about the support I have been providing.</p>
        <p>With kind regards,</p>
        <br /><br />
        <p>International Board Certified Lactation Consultant, IBCLC.</p>
        <p>Lowestoft and Waveney Breastfeeding Support        </p>
      `
      editor?.commands.setContent(form.values.bodyHTML)


              }




          }
      )
  }

}, [editor]);





  const params = useParams();
  const [token, setToken] = useState("");

  const pageData = <>
    <h1>GP Email</h1>


    <form onSubmit={form.onSubmit(
                (values, _event) => {
                  console.log('vals',values)
                    notifications.show({
                        id: 'updateEmail',
                        message: 'Saving',
                        loading:true,
                        autoClose: false,
                      })

                      // values.body



                        API.put('/family/'+params["familyId"]+'/gp',values,token).then(
                        // API.put('/test',values,token).then(
                            (response) => {
                                // if (response.body.id) {
                                    // window.location.replace('/admin/emails');
                                // }
                                notifications.update({
                                    id:'updateEmail',
                                    color: 'teal',
                                    message: 'Email was updated',
                                    icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                                    loading: false,
                                    autoClose: 2000,
                                  });

                            },
                            (e) => {
                                notifications.update({
                                    id:'updateEmail',
                                    color: 'red',
                                    message: 'Error updating the email',
                                    icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                                    loading: false,
                                    autoClose: 2000,
                                  });

                            }
                        )
                },
                (validationErrors, _values, _event) => {
                    console.log('ERRORS',validationErrors)
                    console.log('FORM VALS',_values)
                }
            )}>

    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>


      <RichTextEditor.Content mih={400}/>
    </RichTextEditor>

    <p>
    <Button
      type="submit"
    >Save</Button>

    </p>
    </form>
  </>

  return (
    <>
      {showPage &&
      <AdminNav currentPage="Family" content={pageData} />
      }
    </>
  );
}
