import AdminNav from '../../components/AdminNav';

import {
    Avatar,
    Badge,
    Box,
    Table,
    Group,
    Text,
    ActionIcon,
    Anchor,
    ScrollArea,
    useMantineTheme,
    Button,
    SimpleGrid,
    TextInput,
    rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { API } from '../../classes/API';
import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
// import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
// import Underline from '@tiptap/extension-underline';
// import TextAlign from '@tiptap/extension-text-align';
// import Superscript from '@tiptap/extension-superscript';
// import SubScript from '@tiptap/extension-subscript';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';

import {
    // useSearchParams,
    useLoaderData,
    useParams,
} from "react-router-dom";




export default function EmailForm() {
    // const [users, setUsers] = useState<resultProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    // const [buttonText, setButtonText] = useState<string>("Create User");
    const [token, setToken] = useState("");

    // const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie] = useCookies(['token']);
    const [showPage, setShowPage] = useState(false);

    const contacts = useLoaderData();
    console.log('contacts?',contacts)

    const params = useParams();



    const editor = useEditor({
        extensions: [
          StarterKit,
        //   Underline,
          Link,
        //   Superscript,
        //   SubScript,
        //   Highlight,
        //   TextAlign.configure({ types: ['heading', 'paragraph'] }),
        ],
        // content: content,
        onBlur:(e)=>{
            console.log('blur',e)
            form.values.body = e.editor.getText()
            form.values.bodyHTML = e.editor.getHTML()
        }
      });

/*
      useEffect(() => {
        // this is just an example. do whatever you want to do here
        // to retrieve your editors content from somewhere
        editor?.commands.setContent('beep')
      }, [editor])
*/

    useEffect(() => {
        //Runs only on the first render
        setToken(cookies.token)


        const cookieToken = cookies.token

        API.get('/reauth', cookieToken).then(
            (response) =>  {
                if (response.status === 401) {
                    setCookie("token", "")
                    window.location.replace('/admin/login');
                    return <></>
                } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
                    setShowPage(true)
                }
            },
            error => {
                console.log('ERROR?!?',error)
            }
        );

        if (cookieToken === '') {
            window.location.replace('/admin/login');
            return //<></>
        }

        if (params["emailId"]) {
            editor?.commands.setContent('')

            setIsNew(false)
            // setButtonText("Update User")
            API.get('/email/'+params["emailId"], cookieToken).then(
                (data) => {
                    console.log('got data', data, data.body)
                    if (data.status == 200) {
                        form.setValues({
                            // id: data.body.id,
                            name: data.body.name,
                            subject: data.body.subject,
                          })
                          form.values.body = data.body.body
                          form.values.bodyHTML = data.body.bodyHTML

                        console.log('active', editor);
                          editor?.commands.setContent(data.body.bodyHTML)
                    }
                }
            )
        }

      }, [editor]);

    const theme = useMantineTheme();
    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            // id: 0,
            name: '',
            subject: '',
            body: '',
            bodyHTML: '',
        },

        // TODO password confirmations
        validate: {
            name: (value) => value.trim().length < 2,
            subject: (value) => value.trim().length < 2,
            body: (value) => value.trim().length < 2,

        },
    });


    const emailTest = function(){
      console.log('email test')
      ///email/{emailId}/send
      API.get('/email/'+params["emailId"]+'/test',token)
    }


    const formValues = {}

    const userForm = (
        <Box maw={500} mx="auto">
            <h1>{isNew?"New":"Update"} Email</h1>
            <form onSubmit={form.onSubmit(
                (values, _event) => {
                    notifications.show({
                        id: 'updateEmail',
                        message: 'Saving',
                        loading:true,
                        autoClose: false,
                      })

                      // values.body

                        API.put('/email/'+params["emailId"],values,token).then(
                        // API.put('/test',values,token).then(
                            (response) => {
                                // if (response.body.id) {
                                    // window.location.replace('/admin/emails');
                                // }
                                notifications.update({
                                    id:'updateEmail',
                                    color: 'teal',
                                    message: 'Email was updated',
                                    icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                                    loading: false,
                                    autoClose: 2000,
                                  });

                            },
                            (e) => {
                                notifications.update({
                                    id:'updateEmail',
                                    color: 'red',
                                    message: 'Error updating the email',
                                    icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                                    loading: false,
                                    autoClose: 2000,
                                  });

                            }
                        )
                },
                (validationErrors, _values, _event) => {
                    console.log('ERRORS',validationErrors)
                    console.log('FORM VALS',_values)
                }
            )}>


                <TextInput
                    id="name"
                    label="Name"
                    aria-label="Name"
                    placeholder="Name"
                    type="input"
                    name="parent-first-name"
                    required
                    {...form.getInputProps('name')}
                    my="md"
                />
                <TextInput
                    label="Subject"
                    id="subject"
                    aria-label="Subject"
                    placeholder="Subject"
                    type="input"
                    name="subject"
                    required
                    {...form.getInputProps('subject')}
                    my="md"
                />

<p>Body:</p>

<RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.ClearFormatting />
          <RichTextEditor.Highlight />
          <RichTextEditor.Code />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Blockquote />
          <RichTextEditor.Hr />
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
          <RichTextEditor.Subscript />
          <RichTextEditor.Superscript />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.AlignLeft />
          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />
          <RichTextEditor.AlignRight />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>


      <RichTextEditor.Content/>
    </RichTextEditor>




                <div id="sidebar">
                    <div>
                        <Button type="button" size="md" my="md" loaderPosition="right" loading={isLoading}
                          onClick={emailTest}
                        >
                            Test Email
                        </Button>
                        <Button type="submit" size="md" mx="md" my="md" loaderPosition="right" loading={isLoading}>
                            Update Email
                        </Button>
                        <Button size="md" component="a" href="/admin/email" color='red'> Cancel</Button>
                    </div>
                </div>
            </form>
        </Box>
    );

    return (
        <>
        {showPage &&
        <AdminNav currentPage="Emails" content={userForm} />
        }
        </>
    );
}
