import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {API} from '../classes/API'
import { useCookies } from 'react-cookie';
import { notifications } from '@mantine/notifications';

export default function AuthenticationTitle() {

    const [cookies, setCookie, removeCookie] = useCookies(['lwbf']);

    const form = useForm({
        initialValues: {
          email: '',
          password: '',
        },

        validate: {
          email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
      });

    if (cookies.token !== '') {
        // TODO check token is authentic
        window.location.replace('/admin');
        return <></>
    }

    //TODO forgotten password & remember me

  return (
    <Container size={420} my={40}>
    <form onSubmit={form.onSubmit((values) => {
        API.post("/auth",values).then((response) => {
            console.log(response)
            if (response.status === 200) {


              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
              window.location.replace('/admin');
            } else if (response.status === 401) {
                notifications.show({
                    title: 'Login failed',
                    message: 'Email or password was incorrect',
                    color:"red",
                  })
            }
        },
        (error) => {
            console.log('login failed',error)
        }
        )
    })}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Welcome!
      </Title>
      {/*
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Do not have an account yet?{' '}
        <Anchor size="sm" component="button">
          Create account
        </Anchor>
      </Text>
  */}
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
            label="Email"
            placeholder="you@lowestoftandwaveneybreastfeeding.co.uk"
            required
            {...form.getInputProps('email')}
        />
        <PasswordInput
            label="Password"
            placeholder="Your password"
            required
            mt="md"
            {...form.getInputProps('password')}
        />
        {/*
        <Group position="apart" mt="lg">
          <Checkbox label="Remember me" />
          <Anchor component="button" size="sm">
            Forgot password?
          </Anchor>
        </Group>
        */}
        <Button fullWidth mt="xl" type="submit">
          Sign in
        </Button>
      </Paper>
      </form>
    </Container>
  );
}
