import { useState, useEffect } from 'react';
import { createStyles, Navbar, Group, Code, getStylesRef, rem, Container, SimpleGrid } from '@mantine/core';
import {
  IconBellRinging,
  IconFingerprint,
  IconKey,
  IconDatabaseImport,
  IconUser,
  IconUsersGroup,
  IconSwitchHorizontal,
  IconLogout,
} from '@tabler/icons-react';
import { MantineLogo } from '@mantine/ds';
import {API} from '../classes/API';
import AdminNav from '../components/AdminNav';
import { useCookies } from 'react-cookie';

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,

      [`& .${getStylesRef('icon')}`]: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      [`& .${getStylesRef('icon')}`]: {
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },
  },
}));

const data = [
  { link: '', label: 'Notifications', icon: IconBellRinging },
  { link: '', label: 'Users', icon: IconUser },
  { link: '', label: 'Clients', icon: IconUsersGroup },
  { link: '', label: 'Appointments', icon: IconFingerprint },
  { link: '', label: 'Stock', icon: IconKey },
  { link: '', label: 'Pump Loans', icon: IconDatabaseImport },
];



export default function Admin() {

  const { classes, cx } = useStyles();
  const [active, setActive] = useState('Users');
  const [cookies, setCookie] = useCookies(['lwbf']);
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    //Runs only on the first render
    const cookieToken = cookies.token

    API.get('/reauth',cookieToken).then(
        (response) =>  {
            if (response.status === 401) {
                setCookie("token", "")
                window.location.replace('/admin/login');
                return //<></>
            }
            setShowPage(true)
        },
        error => {
            console.log('ERROR?!?',error)
        }
    );

    if (cookies.token === '') {
        window.location.replace('/admin/login');
        return //<></>
    }
    window.location.replace('/admin/families');
    return

    })

  const links = data.map((item) => (
    <a
      className={cx(classes.link, { [classes.linkActive]: item.label === active })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));

  const content = <>
  	<h1>Dashboard</h1>
  	<p>TODO: Add in dashboard data</p>
 </>

    return (
      <>
      {showPage &&
      <AdminNav currentPage="Dashboard" content={content} />
      }
      </>
    );
  }
