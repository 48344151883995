import AdminNav from '../../components/AdminNav';

import {
    Avatar,
    Badge,
    Box,
    Table,
    Group,
    Text,
    ActionIcon,
    Anchor,
    ScrollArea,
    useMantineTheme,
    Button,
    SimpleGrid,
    TextInput,
    Select,
    Loader,
    Input,
    rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDeviceFloppy, IconDisc, IconPencil, IconTrash } from '@tabler/icons-react';
import { API } from '../../classes/API';
import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { DateTimePicker } from '@mantine/dates';

import {
    // useSearchParams,
    useLoaderData,
    useParams,
} from "react-router-dom";


const jobColors: Record<string, string> = {
    engineer: 'blue',
    manager: 'cyan',
    designer: 'pink',
};


type userSelectProps = {
    'value': string,
    'label': string,
}

export default function FamilyDetails() {
    // const [users, setUsers] = useState<resultProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [buttonText, setButtonText] = useState<string>("Create User");
    const [token, setToken] = useState("");
    const [passwordRequired, setPasswordRequired] = useState(true);
    const [familyData, setFamilyData] = useState<familyProps>();
    const [userData, setUserData] = useState<userSelectProps[]>([]);
    const [assignedUser, setAssignedUser] = useState('');
    const [showPage, setShowPage] = useState(false);

    // const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie] = useCookies(['token']);

    const params = useParams();
    const [dateValue, setDateValue] = useState('');
    const [dateObj, setDateObj] = useState<Date>();

    useEffect(() => {
        //Runs only on the first render
        setToken(cookies.token)

        const cookieToken = cookies.token

        API.get('/reauth', cookieToken).then(
            (response) =>  {
                if (response.status === 401) {
                    setCookie("token", "")
                    window.location.replace('/admin/login');
                    return <></>
                } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
                    setShowPage(true)
                }
            },
            error => {
                console.log('ERROR?!?',error)
            }
        );

        if (cookieToken === '') {
            window.location.replace('/admin/login');
            return //<></>
        }

        if (params["familyId"]) {
            setIsNew(false)
            setButtonText("Update User")
            API.get('/family/'+params["familyId"], cookieToken).then(
                (data) => {
                    if (data.status == 200) {
                        setFamilyData(data.body)

                        const userId = data.body.userId

                        if (data.body.appointment != null) {
                            const dateVal = data.body.appointment.dateOfAppointment
                            const dateTest = new Date(dateVal)
                            setDateObj(dateTest)

                            setAssignedUser(userId)
                            setDateObj(dateTest)
                            setDateValue(dateTest.getFullYear()+'-'+(dateTest.getMonth()+1)+'-'+dateTest.getDate()+' '+dateTest.getHours()+':'+dateTest.getMinutes())
                        }
                        // Load in the use data for assignment
                        if (userData.length == 0) {
                            API.get('/user',cookieToken).then((response) =>  {
                                if (response.status == 200) {
                                    const userDataOptions:userSelectProps[] = []
                                    const jsonData = response.body;
                                    // userDataOptions.push({value:'0',label:'Please select'})
                                    for(let i = 0; i < jsonData.length; i++) {
                                        let element = jsonData[i];
                                        const option:userSelectProps = {
                                            label:element.firstName + ' ' + element.lastName,
                                            value:`${element.id}`,
                                        }
                                        userDataOptions.push(option)
                                    }
                                    setUserData(userDataOptions);
                                    setAssignedUser(`${userId}`)
                                }
                            });
                        }
                    }
                }
            )
        }
      }, []);

    const theme = useMantineTheme();
    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            status: '',
        },

        // TODO password confirmations
        validate: {
            firstName: (value) => value.trim().length < 2,
            lastName: (value) => value.trim().length < 2,
            password: (value) => (isNew || value.trim().length > 0) && value.trim().length < 6 ? 'Please enter a password' : null,
            passwordConfirm: (value, values) =>
            value !== values.password ? 'Passwords did not match' : null,
        },
    });


    const formValues = {}



    const userForm = (
        <Box maw={500} mx="auto">
            <h1>Family Details</h1>
            <Select
                label="Assigned User"
                placeholder="Assigned User"
                data={userData}
                {...form.getInputProps('status',{type:'checkbox'})}
                value={assignedUser}
                onChange={(v)=>{
                    setAssignedUser(`${v}`)
                    notifications.show({
                        id: 'setUser',
                        message: 'Saving',
                        loading:true,
                        autoClose: false,
                      })
                      const cookieToken = cookies.token
                      API.put('/family/'+params["familyId"],{userId:v}, cookieToken).then((resposne)=>{
                        notifications.update({
                          id:'setUser',
                          color: 'teal',
                          message: 'Assigned user was updated',
                          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                          loading: false,
                          autoClose: 2000,
                        });
                      },(error) => {
                        // handle the error
                        notifications.update({
                            id:'setUser',
                            color: 'red',
                            message: 'Error updating the assigned user',
                            icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                            loading: false,
                            autoClose: 2000,
                          });
                      });
                }}
            />

            {familyData?.appointment != null && familyData.appointment.unavailability != '' &&
                <p><strong>Any unavailable days:</strong> {familyData.appointment.unavailability}</p>
            }

            {familyData?.appointment != null &&
            <DateTimePicker
                  label="Please select a preferred date for your appointment"
                  placeholder="Preferred appointment date"
                //   required={true}
                  // maw={400}
                  // mx="auto"
                  value={dateObj}

                  onChange={(v) => {
                    if (v != null) {
                        setDateObj(v)
                        setDateValue(v.getFullYear()+'-'+(v.getMonth()+1)+'-'+v.getDate()+' '+v.getHours()+':'+v.getMinutes())
                    }
                }}
                  //{...form.getInputProps('appointment.niceDateOfAppointment')}
                  minDate={new Date()}
                  rightSection={<IconDeviceFloppy
                    aria-label="Save"
                    onClick={() => {

                        console.log('save',dateValue)

                        notifications.show({
                            id: 'setUser',
                            message: 'Saving',
                            loading:true,
                            autoClose: false,
                          })
                          const cookieToken = cookies.token
                          const appointment = {dateOfAppointment:dateValue}
                          API.put('/family/'+params["familyId"],{appointment:appointment}, cookieToken).then((response)=>{
                            notifications.update({
                              id:'setUser',
                              color: 'teal',
                              message: 'Appointment date was updated',
                              icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                              loading: false,
                              autoClose: 2000,
                            });
                          },(error) => {
                            // handle the error
                            notifications.update({
                                id:'setUser',
                                color: 'red',
                                message: 'Error updating the appointment date',
                                icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                                loading: false,
                                autoClose: 2000,
                              });
                          });
                    }}
                  />}
                />
            }

            <p><strong>Parent Name:</strong> {familyData?.parentFirstName + ' ' + familyData?.parentLastName}</p>
            {familyData?.appointment != null &&
                <p><strong>Parent Pronoun:</strong> {familyData?.parentPronoun}</p>
            }
            <p><strong>Date of Birth:</strong> {familyData?.dateOfBirth}</p>
            <p><strong>Email:</strong> {familyData?.email}</p>
            <p><strong>Address:</strong> <br />
                {familyData?.address1} <br />
                {familyData?.address2} <br />
                {familyData?.city} <br />
                {familyData?.county} <br />
                {familyData?.postcode} <br />
            </p>
            <p><strong>Phone Number:</strong> {familyData?.phoneNumber}</p>

            {
              familyData?.children.map((child) => (
                <>
                <p><strong>Baby Name:</strong> {child.firstName} {child.lastName}</p>
                <p><strong>Baby Date of Birth:</strong> {child.dateOfBirth}</p>
                </>
              ))}

{familyData?.appointment != null && <>
            <p><strong>Named Midwife:</strong> {familyData?.namedMidwife}</p>
            <p><strong>Named Health Visitor:</strong> {familyData?.namedHealthVisitor}</p>
</>
}
        </Box>
    );

    return (
        <>
        {showPage &&
        <AdminNav currentPage="Families" content={userForm} />
        }
        </>
    );
}
