import AdminNav from '../../components/AdminNav';

import {
    Avatar,
    Badge,
    Box,
    Table,
    Group,
    Text,
    ActionIcon,
    Anchor,
    ScrollArea,
    useMantineTheme,
    Button,
    SimpleGrid,
    TextInput,
    Select,
    Loader,
    Input,
    rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconDeviceFloppy, IconDisc, IconPencil, IconTrash } from '@tabler/icons-react';
import { API } from '../../classes/API';
import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';
import { DatePickerInput } from '@mantine/dates';

import {
    // useSearchParams,
    useLoaderData,
    useParams,
} from "react-router-dom";


const jobColors: Record<string, string> = {
    engineer: 'blue',
    manager: 'cyan',
    designer: 'pink',
};


type familySelectProps = {
    'value': string,
    'label': string,
}


export default function PumpDetails() {
    // const [users, setUsers] = useState<resultProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [buttonText, setButtonText] = useState<string>("Create User");
    const [token, setToken] = useState("");
    const [passwordRequired, setPasswordRequired] = useState(true);
    const [pumpData, setPumpData] = useState<pumpProps>({
        id: 0,
        familyId: null,
        name: '',
        status: '',
        loanStart: '',
        loanEnd: '',

    });

    // We store all the family data so we have access to the pump request start date
    const [familyData, setFamilyData] = useState<familyProps[]>([]);
    const [familyOptions, setFamilyOptions] = useState<familySelectProps[]>([]);
    const [assignedFamily, setAssignedFamily] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showPage, setShowPage] = useState(false);

    const getFamilyData = (familyId:number):familyProps | null => {
      var famData = null
      console.log('GETTING FAM DATA', familyId, familyData);
      var i = 0
      for (i = 0; i < familyData.length; i++) {
        const fam = familyData[i]
        console.log('fam test', fam.id, familyId)
          if (fam.id == familyId) {
            console.log('got fam',fam)
              // return fam
              famData = fam
              break;
          }

      }
        // familyData.forEach((fam, index) => {
        // })
        return famData
    }


    // const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie] = useCookies(['token']);

    const params = useParams();
    const [startDateValue, setStartDateValue] = useState('');
    const [startDateObj, setStartDateObj] = useState<Date>();
    const [endDateValue, setEndDateValue] = useState('');
    const [endDateObj, setEndDateObj] = useState<Date>();

    useEffect(() => {
        //Runs only on the first render
        setToken(cookies.token)

        const cookieToken = cookies.token

        API.get('/reauth', cookieToken).then(
            (response) =>  {
                if (response.status === 401) {
                    setCookie("token", "")
                    window.location.replace('/admin/login');
                    return <></>
                } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
                    setShowPage(true)
                }
            },
            error => {
                console.log('ERROR?!?',error)
            }
        );

        if (cookieToken === '') {
            window.location.replace('/admin/login');
            return //<></>
        }

        if (params["pumpId"]) {
            setIsNew(false)
            setButtonText("Update Pump")
            API.get('/pump/'+params["pumpId"], cookieToken).then(
                (data) => {
                    if (data.status == 200) {
                        setPumpData(data.body)

                        const familyId = data.body.familyId
                        console.log('pump data',data.body)



                        if (data.body.familyId != null) {
                            const startDateVal = data.body.loanStart
                            const startDateObj = new Date(startDateVal)
                            setStartDateObj(startDateObj)
                            setStartDateValue(startDateObj.getFullYear()+'-'+(startDateObj.getMonth()+1)+'-'+startDateObj.getDate())

                            const endDateVal = data.body.loanEnd
                            const endDateObj = new Date(endDateVal)
                            setEndDateObj(endDateObj)
                            setEndDateValue(endDateObj.getFullYear()+'-'+(endDateObj.getMonth()+1)+'-'+endDateObj.getDate())

                            setAssignedFamily(`${familyId}`)
                            setStartDate(data.body.loanStart)
                            console.log('set the assigned family to ', familyId, data.body)
                            // setDateObj(dateTest)
                            // setDateValue(dateTest.getFullYear()+'-'+(dateTest.getMonth()+1)+'-'+dateTest.getDate()+' '+dateTest.getHours()+':'+dateTest.getMinutes())
                        }



                        console.log('test -1')
                        // Load in the family data for assignment
                        if (familyOptions.length == 0) {
                            console.log('test 0')
                            API.get(`/pump/${params["pumpId"]}/pump-requests`,cookieToken).then((response) =>  {
                                console.log('test 1')
                                if (response.status == 200) {
                                    console.log('test 2')
                                    const familyDataOptions:familySelectProps[] = []
                                    const jsonData = response.body;

                                    // Store the family data to reference later
                                    setFamilyData(jsonData)
                                    // userDataOptions.push({value:'0',label:'Please select'})
                                    for(let i = 0; i < jsonData.length; i++) {
                                        console.log('test 3')
                                        let element = jsonData[i];
                                        const option:familySelectProps = {
                                            label:element.parentFirstName + ' ' + element.parentLastName,
                                            value:`${element.id}`,
                                        }
                                        familyDataOptions.push(option)
                                    }
                                    console.log('familyDataOptions',familyDataOptions)
                                    setFamilyOptions(familyDataOptions);
                                    // setAssignedFamily(`${familyId}`)
                                }
                            });
                        }
                    }
                }
            )
        }
      }, []);

    const theme = useMantineTheme();
    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            status: '',
        },

        // TODO password confirmations
        validate: {
            firstName: (value) => value.trim().length < 2,
            lastName: (value) => value.trim().length < 2,
            password: (value) => (isNew || value.trim().length > 0) && value.trim().length < 6 ? 'Please enter a password' : null,
            passwordConfirm: (value, values) =>
            value !== values.password ? 'Passwords did not match' : null,
        },
    });


    const formValues = {}



    const userForm = (
        <Box maw={500} mx="auto" style={{ width: '100%' }}>
            <h1>Pump Details</h1>
            <p><strong>Status:</strong> {pumpData?.status}</p>
            <Select
                label="Assigned Family"
                placeholder="Assigned Family"
                data={familyOptions}
                {...form.getInputProps('status',{type:'checkbox'})}
                value={assignedFamily}
                onChange={(v)=>{
                    setAssignedFamily(`${v}`)
                    notifications.show({
                        id: 'setUser',
                        message: 'Saving',
                        loading:true,
                        autoClose: false,
                      })
                      const cookieToken = cookies.token
                      const tmpPump = pumpData
                      if (v != null) {
                        const family = getFamilyData(Number(v))
                        if (family != null) {
                          console.log('family change deets', family)
                          // setStartDateValue(family.pumpRequest)

                          const startDateObj = new Date(family.pumpRequest)
                          const endDateObj = new Date(family.pumpRequest)
                          setStartDateObj(startDateObj)
                          setStartDateValue(startDateObj.getFullYear()+'-'+(startDateObj.getMonth()+1)+'-'+startDateObj.getDate())

                          // Set the end datee one month in the future
                          const tmpEndDate = new Date(endDateObj.setMonth(endDateObj.getMonth() + 1));
                          setEndDateObj(tmpEndDate)
                          const endDateValue = tmpEndDate.getFullYear()+'-'+(tmpEndDate.getMonth()+1)+'-'+tmpEndDate.getDate()
                          setEndDateValue(endDateValue)

                          tmpPump.loanStart = family.pumpRequest
                          tmpPump.loanEnd = endDateValue
                        }
                      }
                      tmpPump.familyId = v
                      // console.log('tmpPump',tmpPump)
                      setPumpData(tmpPump)
                      API.put('/pump/'+params["pumpId"],
                       pumpData,
                        cookieToken).then((response)=>{
                          console.log(response)
                        notifications.update({
                          id:'setUser',
                          color: 'teal',
                          message: 'Assigned family was updated',
                          icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                          loading: false,
                          autoClose: 2000,
                        });
                      },(error) => {
                        // handle the error
                        notifications.update({
                            id:'setUser',
                            color: 'red',
                            message: 'Error updating the assigned family',
                            icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                            loading: false,
                            autoClose: 2000,
                          });
                      });
                }}
            />


            {pumpData?.familyId != null &&

            <>
            <DatePickerInput
                  label="Please select a start date for the pump loan"
                  placeholder="Loan start date"
                  dropdownType="modal"
                //   required={true}
                  // maw={400}
                  // mx="auto"
                  value={startDateObj}

                  onChange={(v) => {
                    if (v != null) {
                        setStartDateObj(v)
                        setStartDateValue(v.getFullYear()+'-'+(v.getMonth()+1)+'-'+v.getDate())
                    }
                }}
                  //{...form.getInputProps('appointment.niceDateOfAppointment')}
                  minDate={new Date()}
                  rightSection={<IconDeviceFloppy
                    aria-label="Save"
                    onClick={() => {

                        console.log('save',startDateValue)

                        notifications.show({
                            id: 'setUser',
                            message: 'Saving',
                            loading:true,
                            autoClose: false,
                          })
                          const cookieToken = cookies.token



                          const tmpPump = pumpData
                          if (startDateValue != null) {
                              tmpPump.loanStart = startDateValue
                          }
                          setPumpData(tmpPump)


                          API.put('/pump/'+params["pumpId"],
                            pumpData,

                            cookieToken).then((response)=>{
                            notifications.update({
                              id:'setUser',
                              color: 'teal',
                              message: 'Start date was updated',
                              icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                              loading: false,
                              autoClose: 2000,
                            });
                          },(error) => {
                            // handle the error
                            notifications.update({
                                id:'setUser',
                                color: 'red',
                                message: 'Error updating the start date',
                                icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                                loading: false,
                                autoClose: 2000,
                              });
                          });
                    }}
                  />


                }
                />
            <DatePickerInput
                  label="Pump return date"
                  placeholder="Pump return date"
                  dropdownType="modal"
                //   required={true}
                  // maw={400}
                  // mx="auto"
                  value={endDateObj}

                  onChange={(v) => {
                    if (v != null) {
                        setEndDateObj(v)
                        setEndDateValue(v.getFullYear()+'-'+(v.getMonth()+1)+'-'+v.getDate())
                    }
                }}
                  //{...form.getInputProps('appointment.niceDateOfAppointment')}
                  minDate={new Date()}
                  rightSection={<IconDeviceFloppy
                    aria-label="Save"
                    onClick={() => {

                        console.log('save',endDateValue)

                        notifications.show({
                            id: 'setUser',
                            message: 'Saving',
                            loading:true,
                            autoClose: false,
                          })
                          const cookieToken = cookies.token


                          const tmpPump = pumpData
                          if (endDateValue != null) {
                              tmpPump.loanEnd = endDateValue
                          }
                          setPumpData(tmpPump)



                          API.put('/pump/'+params["pumpId"],
                            pumpData,
                            cookieToken).then((response)=>{
                            notifications.update({
                              id:'setUser',
                              color: 'teal',
                              message: 'End date was updated',
                              icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                              loading: false,
                              autoClose: 2000,
                            });
                          },(error) => {
                            // handle the error
                            notifications.update({
                                id:'setUser',
                                color: 'red',
                                message: 'Error updating the end date',
                                icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                                loading: false,
                                autoClose: 2000,
                              });
                          });
                    }}
                  />
                }
                />
                </>
            }


            {/*pumpData?.familyId != null && <>
            <p><strong>Parent Name:</strong> {pumpData?.name}</p>
            </>*/}

        </Box>
    );

    return (
        <>
        {showPage &&
        <AdminNav currentPage="Families" content={userForm} />
        }
        </>
    );
}
