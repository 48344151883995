import AdminNav from '../../components/AdminNav';

import {
    Avatar,
    Badge,
    Table,
    Group,
    Text,
    ActionIcon,
    Anchor,
    ScrollArea,
    useMantineTheme,
    Button,
    Tooltip,
    Modal,
} from '@mantine/core';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { API } from '../../classes/API';
import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDisclosure } from '@mantine/hooks';

const jobColors: Record<string, string> = {
    engineer: 'blue',
    manager: 'cyan',
    designer: 'pink',
};

export default function UserList() {

    const [users, setUsers] = useState<userProps[]>([]);
    const [token, setToken] = useState('');
    const [deleteSelected, setDeleteSelected] = useState(0);
    const [cookies, setCookie] = useCookies(['token']);
    const [showPage, setShowPage] = useState(false);

    const [delOpened, delOpenHandlers] = useDisclosure(false);

    const test = (id:number) => {
      setDeleteSelected(id)
      delOpenHandlers.open()
    }

    useEffect(() => {
      //Runs only on the first render
      const cookieToken = cookies.token
        setToken(cookies.token)

            API.get('/reauth', cookieToken).then(
            (response) =>  {
                if (response.status === 401) {
                    setCookie("token", "")
                    window.location.replace('/admin/login');
                    return <></>
                } else if (response.status === 200) {
                  // Set the expiry date
                  var date = new Date();
                  var newDate = new Date(date.setMonth(date.getMonth() + 2));
                  setCookie('token',response.body.token, {expires: newDate})
                    setShowPage(true)
                  }
            },
            error => {
                console.log('ERROR?!?',error)
            }
        );

        API.get('/user',cookieToken).then((response) =>  {
          if (response.status == 200) {
            const jsonData = response.body;
            setUsers(jsonData);
          }
        });
        if (cookieToken === '') {
            window.location.replace('/admin/login');
            // return <></>
            return
        }
      }, []);

    const theme = useMantineTheme();

    const deleteClick = () => {
      API.delete(`/user/${deleteSelected}`,token).then((response) =>  {
        window.location.replace('/admin/users');
    });
}

  const userTable = (
    <>
      <Modal opened={delOpened} onClose={delOpenHandlers.close} title="Delete User">
        {
          <>
          <p>Are you sure?</p>
          <Button onClick={deleteClick}>Yes</Button>
          <Button onClick={delOpenHandlers.close}>No</Button>
          </>
        }
      </Modal>


    <h1>Users</h1>
    <Button
    component='a'
    href="/admin/users/add"
    >Add new</Button>
    <ScrollArea>
      <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
        <thead>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th />
          </tr>
        </thead>
        <tbody>{users.map((user) => (
      <tr key={user.email}>
      <td>
    <Text fz="sm">
      {user.firstName} {user.lastName}
    </Text>
  </td>
  <td>
    <Anchor component="button" size="sm">
      {user.email}
    </Anchor>
  </td>
  <td>
    <Group spacing={0} position="right">
        <a href={"/admin/user/" + user.id}>
        <Tooltip
          label="Edit"
          color="blue"
          withArrow
          >
      <ActionIcon>
        <IconPencil size="1rem" stroke={1.5} />
      </ActionIcon>
      </Tooltip>
      </a>
      <Tooltip
        label="Delete"
        color="red"
        withArrow
      >
      <ActionIcon color="red">
        <IconTrash size="1rem" stroke={1.5} onClick={()=>test(user.id)} />
      </ActionIcon>
      </Tooltip>
    </Group>
  </td>
</tr>

        ))}</tbody>
      </Table>
    </ScrollArea>
    </>
  );

    return (
      <>
      {showPage &&
      <AdminNav currentPage="Users" content={userTable} />
      }
      </>
    );
  }
