import { SimpleGrid, Box, Center, Text, Button } from '@mantine/core';

export default function Root() {
    return (
      <>
        <Box maw={500} mx="auto">
          <Text ta="center"><h1>Lowestoft and Waveney Breastfeeding Support</h1></Text>
          <SimpleGrid cols={2}>
            <Button component="a" href="/appointment" variant="outline" h="200px" >Appointment</Button>
            <Button component="a" href="/pump-loan" variant="outline" h="200px" >Pump loan</Button>

          </SimpleGrid>
        </Box>
      </>
    );
  }