import AdminNav from '../../components/AdminNav';

import {
    Avatar,
    Badge,
    Box,
    Table,
    Group,
    Text,
    ActionIcon,
    Anchor,
    ScrollArea,
    useMantineTheme,
    Button,
    SimpleGrid,
    TextInput,
    rem,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { API } from '../../classes/API';
import { Key, ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { notifications } from '@mantine/notifications';
import {
    // useSearchParams,
    useLoaderData,
    useParams,
} from "react-router-dom";
import { IconCheck, IconAlertCircle } from '@tabler/icons-react';


const jobColors: Record<string, string> = {
    engineer: 'blue',
    manager: 'cyan',
    designer: 'pink',
};

export default function UserList() {
    // const [users, setUsers] = useState<resultProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(true);
    const [buttonText, setButtonText] = useState<string>("Create User");
    const [token, setToken] = useState("");
    const [passwordRequired, setPasswordRequired] = useState(true);
    const [userData, setUserData] = useState<userProps>();
    const [showPage, setShowPage] = useState(false);

    // const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie] = useCookies(['token']);

    const contacts = useLoaderData();
    console.log('contacts?',contacts)

    const params = useParams();

    useEffect(() => {
        //Runs only on the first render
        setToken(cookies.token)

        const cookieToken = cookies.token

        API.get('/reauth', cookieToken).then(
            (response) =>  {
                if (response.status === 401) {
                    setCookie("token", "")
                    window.location.replace('/admin/login');
                    return <></>
                } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
                    setShowPage(true)
                }
            },
            error => {
                console.log('ERROR?!?',error)
            }
        );

        if (cookieToken === '') {
            window.location.replace('/admin/login');
            return //<></>
        }

        if (params["userId"]) {
            setIsNew(false)
            setButtonText("Update User")
            API.get('/user/'+params["userId"], cookieToken).then(
                (data) => {
                    console.log('got data', data, data.body)
                    if (data.status == 200) {
                        setUserData(data.body)
                        setPasswordRequired(false)
                        form.setValues({
                            firstName: data.body.firstName,
                            lastName: data.body.lastName,
                            email: data.body.email,
                          })
                    }
                }
            )
        }

      }, []);

    const theme = useMantineTheme();
    const form = useForm({
        validateInputOnBlur: true,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
        },

        // TODO password confirmations
        validate: {
            firstName: (value) => value.trim().length < 2,
            lastName: (value) => value.trim().length < 2,
            password: (value) => (isNew || value.trim().length > 0) && value.trim().length < 6 ? 'Please enter a password' : null,
            passwordConfirm: (value, values) =>
            value !== values.password ? 'Passwords did not match' : null,
        },
    });


    const formValues = {}

    const userForm = (
        <Box maw={500} mx="auto">
            <h1>{isNew?"New":"Update"} User</h1>
            <form onSubmit={form.onSubmit(
                (values, _event) => {
                    setIsLoading(true)
                    if (isNew) {
                        API.post('/user',values,token).then(
                            (response) => {
                              if (response.status === 201) {
                                window.location.replace('/admin/users');
                              }
                            }
                        )
                    } else {

                        API.put('/user/'+userData?.id,values,token).then(
                            (response) => {
                                // console.log(response)
                                if (response.status === 200) {
                                  if (response.body.id) {

                                      notifications.show({
                                        id: 'setUser',
                                        color: 'teal',
                                        message: 'User was updated',
                                        icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
                                        loading: false,
                                        autoClose: 2000,
                                      })
                                  }
                                } else {
                                  console.log('nope?',response);
                                  notifications.show({
                                    id: 'setUser',
                                    color: 'red',
                                    message: 'Error updating the user',
                                    icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                                    loading: false,
                                    autoClose: 2000,
                                  })

                                }
                                setIsLoading(false)
                            },
                            /*
                            (e) => {
                              alert('ERROR?');
                            }
                            */
                        )

                    }
                },
                (validationErrors, _values, _event) => {
                    console.log('ERRORS',validationErrors)
                    console.log('FORM VALS',_values)
                }
            )}>


                <TextInput
                    id="first-name"
                    label="First Name"
                    aria-label="First Name"
                    placeholder="First Name"
                    type="input"
                    name="parent-first-name"
                    required
                    {...form.getInputProps('firstName')}
                    my="md"
                />
                <TextInput
                    label="Last Name"
                    id="last-name"
                    aria-label="Last Name"
                    placeholder="Last Name"
                    type="input"
                    name="parent-last-name"
                    required
                    {...form.getInputProps('lastName')}
                    my="md"
                />

                <TextInput
                    label="Email Address"
                    aria-label="Email Address"
                    placeholder="example@example.com"
                    type="email"
                    required
                    {...form.getInputProps('email')}
                    my="md"
                />

                <TextInput
                    label="Password"
                    placeholder="Password"
                    type="password"
                    required={passwordRequired}
                    {...form.getInputProps('password')}
                />
                <TextInput
                    placeholder="Confirm Password"
                    type="password"
                    required={passwordRequired}
                    {...form.getInputProps('passwordConfirm')}
                />

                <div id="sidebar">
                    <div>
                        <Button type="submit" size="md" my="md" loaderPosition="right" loading={isLoading}>
                            {buttonText}
                        </Button>
                        <Button size="md" component="a" href="/admin/users" color='red' mx="md"> Cancel</Button>
                    </div>
                </div>
            </form>
        </Box>
    );

    return (
        <>
        {showPage &&
        <AdminNav currentPage="Users" content={userForm} />
        }
        </>
    );
}
