// import {useState, ReactNode} from 'react';
import React, {Component} from 'react';
import {ReactNode} from 'react';
import { useState } from 'react';
import { createStyles, Navbar, Group, Code, getStylesRef, rem, Container, SimpleGrid, Flex, ScrollArea } from '@mantine/core';


import {
    IconBellRinging,
    IconFingerprint,
    IconKey,
    IconDatabaseImport,
    IconUser,
    IconUsersGroup,
    IconSwitchHorizontal,
    IconLogout,
    IconClipboardText,
    IconBoxSeam,
    IconReceipt,
    IconMail,
  } from '@tabler/icons-react';
  import { MantineLogo } from '@mantine/ds';
  import {API} from '../classes/API';


  const useStyles = createStyles((theme) => ({
    header: {
      paddingBottom: theme.spacing.md,
      justifyContent: 'center',
      marginBottom: `calc(${theme.spacing.md} * 1.5)`,
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [`& .${getStylesRef('icon')}`]: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    },

    version: {
      color: '#797979',
      fontSize: '.75em',
      margin: 0,
      position: 'absolute',
      top: '1em',
      right: '1em',
    },

    linkIcon: {
      ref: getStylesRef('icon'),
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        [`& .${getStylesRef('icon')}`]: {
          color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        },
      },
    },
  }));

  const data = [
    // { link: '/admin', label: 'Dashboard', icon: IconBellRinging },
    { link: '/admin/families', label: 'Families', icon: IconUsersGroup },
    { link: '/admin/users', label: 'Users', icon: IconUser },
    { link: '/admin/email', label: 'Emails', icon: IconMail },
    { link: '/admin/pumps', label: 'Pumps', icon: IconBoxSeam },
  ];



interface Props {
    // children: JSX.Element
    content: ReactNode,
    // content: Component
    currentPage: string,
}

export default function AdminNav(props: Props) {


    const { classes, cx } = useStyles();
    // const [active, setActive] = useState('Users');
    const [active, setActive] = useState(props.currentPage);

  const links = data.map((item) => (
    <a
      className={cx(classes.link, { [classes.linkActive]: item.label === active })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        // event.preventDefault();
        setActive(item.label);
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));



// TODO replace with AppShell
    return (
      <Flex>
        <Navbar width={{ sm: 300 }} p="md">
          <Navbar.Section grow>
            <Group className={classes.header} position="apart">
              <img src="/logo.png" />
            </Group>
            {links}
          </Navbar.Section>
          <Navbar.Section className={classes.footer}>
            <a href="/admin/logout" className={classes.link}>
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Logout</span>
            </a>
            <p className={classes.version}>v1.0-pre-11</p>
          </Navbar.Section>
        </Navbar>
        <Container fluid style={{ width: '100%' }}>
          <ScrollArea type='auto'>
            {props.content}
          </ScrollArea>
        </Container>
      </Flex>
    );
  }
