// import { useState } from 'react';
import { useState, useEffect } from 'react';
import { MantineProvider, Text, TextInput, Textarea, Box, Button, Checkbox, Switch, Radio, Group, SimpleGrid, Stepper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput } from '@mantine/dates';
import { API } from '../classes/API';
// import { Redirect, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
export default function Appointment() {


    const [active, setActive] = useState(0);
    const [nextButton, setNextButton] = useState('');


const form = useForm({
    validateInputOnBlur: true,
    initialValues: {
        parentFirstName: '',
        parentLastName: '',
        pumpRequest: null,
        email: '',
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: '',
        phoneNumber: '',
        // phone: '',
    },

    validate: {
      parentFirstName: (value) => value.trim().length < 2,
      parentLastName: (value) => value.trim().length < 2,
    },


});



//   });



  const formValues = {}
  const navigate = useNavigate();
    return (
        <Box maw={500} mx="auto">

            <h1>Borrow a breast pump</h1>
                <form onSubmit={form.onSubmit(
                    (values, _event) => {
                        console.log('vals#1',values)
                        API.post('/pump-request',values).then((response) =>{
                            console.log('submit response', response)
                            if (response.status === 201) {
                              navigate('/pump-loan/confirmation');
                            } else {
                              // Failed
                              /*
                              notifications.update({
                                id:'setUser',
                                color: 'red',
                                message: 'Error saving the appointment',
                                icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
                                loading: false,
                                autoClose: 2000,
                              });
                              */
                }
                          })
                    },
                    (validationErrors, _values, _event) => {
                    console.log('ERRORS',validationErrors)
                    console.log('FORM VALS',_values)
                    // API.test();
                    //API.pist
                    }
                )}>


                <p>Full name</p>


            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                <TextInput
                    id="first-name"
                    aria-label="First Name"
                    placeholder="First Name"
                    type="input"
                    name="parent-first-name"
                    required
                    {...form.getInputProps('parentFirstName')}
                />
                <TextInput
                    id="last-name"
                    aria-label="Last Name"
                    placeholder="Last Name"
                    type="input"
                    name="parent-last-name"
                    required
                    {...form.getInputProps('parentLastName')}
                />
            </SimpleGrid>


            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} my="md">
            <DateInput
                label="Start date"
                placeholder="Start date"
                required={true}
                // maw={400}
                // mx="auto"
                {...form.getInputProps('pumpRequest')}
            />
            </SimpleGrid>

            <TextInput
                label="Email Address"
                aria-label="Email Address"
                placeholder="example@example.com"
                type="email"
                required
                {...form.getInputProps('email')}
                my="md"
            />



            <p>Address</p>
            <TextInput
                aria-label="Address Line 1"
                placeholder="Address Line 1"
                type="text"
                required={true}
                {...form.getInputProps('address1')}
            />
            <TextInput
                aria-label="Address Line 2"
                placeholder="Address Line 2"
                type="text"
                {...form.getInputProps('address2')}
            />
            <TextInput
                aria-label="Town/City"
                placeholder="Town/City"
                type="text"
                required={true}
                {...form.getInputProps('city')}
            />
            <TextInput
                aria-label="County"
                placeholder="County"
                type="text"
                {...form.getInputProps('county')}
            />
            <TextInput
                aria-label="Postcode"
                placeholder="Postcode"
                type="text"
                required={true}
                {...form.getInputProps('postcode')}
            />
            <TextInput
                aria-label="Phone Number"
                placeholder="Phone Number"
                type="text"
                {...form.getInputProps('phoneNumber')}
            />



        <div
            id="sidebar"
        >
            <div>
                <Button type="submit" size="md" my="md">
                    Send message
                </Button>
            </div>
        </div>
            </form>
      </Box>
    );
  }
