import AdminNav from '../../components/AdminNav';

import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  ScrollArea,
  useMantineTheme,
} from '@mantine/core';
import { IconPencil, IconTrash, IconFileText } from '@tabler/icons-react';

import { API } from '../../classes/API';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const jobColors: Record<string, string> = {
  engineer: 'blue',
  manager: 'cyan',
  designer: 'pink',
};
export default function AppointmentList() {
/*
const data = [
    {
        name: 'Family 1',
        email: 'family1@domain.com',
        date: 'Unconfirmed',
    },
    {
        name: 'Family 2',
        email: 'family2@domain.com',
        date: '1st August 2023 12:00pm',
    },
]
*/

const [families, setFamilies] = useState<familyProps[]>([]);
// const [token, setToken] = useState('');
const [cookies, setCookie] = useCookies(['token']);
const [showPage, setShowPage] = useState(false);


useEffect(
    () => {
        //Runs only on the first render
        const cookieToken = cookies.token
            API.get('/reauth', cookieToken).then(
            (response) =>  {
                if (response.status === 401) {
                    setCookie("token", "")
                    window.location.replace('/admin/login');
                    return <></>
                } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
                    setShowPage(true)
                }
            },
            error => {
                console.log('ERROR?!?',error)
            }
        );

        API.get('/appointment',cookieToken).then((response) =>  {
            const jsonData = response.body;
            console.log('got family data',jsonData)
            setFamilies(jsonData);
        });
        if (cookieToken === '') {
            window.location.replace('/admin/login');
            return
        }
    },
    []
);


  const theme = useMantineTheme();
  const userTable = (
    <>
    <h1>Appointments</h1>
    <ScrollArea>
      <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
        <thead>
          <tr>
            <th>Family</th>
            <th>Email</th>
            <th>Date</th>
            <th />
          </tr>
        </thead>
        <tbody>{families.map((item) => (
    <tr key={item.id}>
          <td>
        <Text fz="sm">
          <a href={"/admin/appointment/"+item.id}>{item.parentFirstName} {item.parentLastName}</a>
        </Text>
      </td>
      <td>
        <Anchor component="button" size="sm">
          {item.email}
        </Anchor>
      </td>
      <td>
        <Anchor component="button" size="sm">
          {item.date}
        </Anchor>
      </td>
      <td>
        <Group spacing={0} position="right">
          <ActionIcon>
            <IconFileText size="1rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon>
            <IconPencil size="1rem" stroke={1.5} />
          </ActionIcon>
          <ActionIcon color="red">
            <IconTrash size="1rem" stroke={1.5} />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  ))}</tbody>
      </Table>
    </ScrollArea>
    </>
  );

    return (
      <>
      {showPage &&
      <AdminNav currentPage="Appointments" content={userTable} />
      }
      </>
    );
  }
