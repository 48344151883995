
class API{
    static URL:string = 'https://api.lowestoftandwaveneybreastfeeding.co.uk'
    //  static URL:string = 'http://192.168.0.247:8084'
    //  static URL:string = 'http://192.168.0.178:8084'

    static async get(url: string, token:string){
        const ret = await API.call(url,"GET",{}, token);
        return ret
    }

    static async post(url: string,
        // data: string
        data: object,
        token:string = ''
    ){
        const ret = await API.call(url,"POST",data, token);
        return ret
    }

    static async put(url: string,
        // data: string
        data: object,
        token:string
    ){
        const ret = await API.call(url,"PUT",data, token);
        return ret
    }

    static async patch(url: string,
        // data: string
        data: object,
        token:string
    ){
        const ret = await API.call(url,"PATCH",data, token);
        return ret
    }

    static async delete(url: string,
        token:string
    ){
        const ret = await API.call(url,"DELETE", null, token);
        return ret
    }

    static async call(endpoint: string, method: string,
        // data: string
        data:any,
        token:string
    ) {
        let url = API.URL + endpoint;

        var formData:FormData|null = new FormData();

        for ( var key in data ) {
            formData.append(key, data[key]);
        }

    //    var formData = JSON.stringify(data)

    var jsonData:string|null = JSON.stringify(data)

        if (method == "GET") {
            formData = null
            jsonData = null

        }

        const headers = {
            'content-type': 'application/json',
            'Authorization':'',
        }

        if (token !== '') {
            headers.Authorization = 'Bearer ' + token;
        }

        // Default options are marked with *
        // const response = await fetch(url, {
        const response = await fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: jsonData, // body data type must match "Content-Type" header
            // body: formData,
        });

        if (response.status == 200) {
            const responseData = await response.json(); // parses JSON response into native JavaScript objects
            return {
                body:responseData,
                status: response.status,
            }

        } else {
            return {
                body: '',
                status: response.status,
            }
        }

        // return response; // parses JSON response into native JavaScript objects
        // return response.then(API.handleResponse); // parses JSON response into native JavaScript objects
    }
}
export {API};
