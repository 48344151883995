import AdminNav from '../../components/AdminNav';



import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  ScrollArea,
  useMantineTheme,
  Tooltip,
  Button,
  rem,
} from '@mantine/core';
import { IconPencil, IconTrash, IconPlus, IconCheck, IconAlertCircle} from '@tabler/icons-react';
import { API } from '../../classes/API';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { notifications } from '@mantine/notifications';

export default function PumpList() {
  const [cookies, setCookie] = useCookies(['token']);
  const [pumps, setPumps] = useState<pumpProps[]>([]);
  const [showPage, setShowPage] = useState(false);
  const [token, setToken] = useState("");

  useEffect(
    () => {
        //Runs only on the first render
        const cookieToken = cookies.token
        setToken(cookieToken)
            API.get('/reauth', cookieToken).then(
            (response) =>  {
                if (response.status === 401) {
                    setCookie("token", "")
                    window.location.replace('/admin/login');
                    return <></>
                } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
                    setShowPage(true)
                }
            },
            error => {
                console.log('ERROR?!?',error)
            }
        );

        API.get('/pumps',cookieToken).then((response) =>  {
          if (response.status == 200) {
            const jsonData = response.body;
            console.log('got pump data',jsonData)
            setPumps(jsonData);
          }
        });
        if (cookieToken === '') {
            window.location.replace('/admin/login');
            return
        }
    },
    []
);


  const theme = useMantineTheme();

  const createPump = () => {
     notifications.show({
      id: 'createPump',
      message: 'Creating new pump',
      loading:true,
      autoClose: false,
    })
    API.post('/pumps',{}, token).then(
      (data) => {
        console.log('got data', data, data.body)
        if (data.status == 200) {
          notifications.update({
            id: 'createPump',
            color: 'teal',
            message: 'Pump was created',
            icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
            loading: false,
            autoClose: 2000,
          })

          notifications.show({
            id: 'createPumpReload',
            // color: 'teal',
            message: 'Refreshing page',
            icon: <IconCheck style={{ width: rem(18), height: rem(18) }} />,
            loading: true,
            // autoClose: 2000,
          })
          window.location.replace('/admin/pumps');

        } else {
          notifications.update({
            id:'createPump',
            color: 'red',
            message: 'Failed to create pump',
            icon: <IconAlertCircle style={{ width: rem(18), height: rem(18) }} />,
            loading: false,
            autoClose: 2000,
          });

        }
      }
    )
}

  const userTable = (
    <>
    <div className='flex items-center'>
      <h1>Pumps</h1>
      <div className='grow flex justify-end'>
        <Button className='p-2' onClick={createPump}>
          <IconPlus />
        </Button>
      </div>
    </div>
    <ScrollArea>
      <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Family</th>
            <th>End Date</th>
            <th />
          </tr>
        </thead>
        <tbody>


        {
                            pumps.map((pump) => (
                                <tr key={pump.id}>
                                    <td>
                                        <Text fz="sm">
                                            {pump.id}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text fz="sm">
                                            {pump.status}
                                        </Text>
                                    </td>
                                    <td>
                                        {
                                          (pump.familyId !== null) &&
                                        `${pump.name}`
                                        }
                                    </td>
                                    <td>
                                    {
                                          (pump.familyId !== null) &&
                                        `${pump.loanEnd}`
                                        }
                                    </td>
                                    <td>
                                        <Group spacing={0} position="right">


                                    <a href={'/admin/pumps/' + pump.id}>
                                    <Tooltip
                                                label="Edit"
                                                color="blue"
                                                withArrow
                                                >
                                            <ActionIcon>
                                                <IconPencil size="1rem" stroke={1.5} />
                                            </ActionIcon>
                                            </Tooltip>
                                            </a>
                                        </Group>
                                    </td>
                                </tr>

                            ))
                        }



        </tbody>
      </Table>
    </ScrollArea>
    </>
  );

    return (
      <>
      {showPage &&
      <AdminNav currentPage="Inventory" content={userTable} />
      }
      </>
    );
  }
