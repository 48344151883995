import AdminNav from '../../components/AdminNav';

import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  ScrollArea,
  useMantineTheme,
  Tooltip,
} from '@mantine/core';
import { IconPencil, IconTrash, IconFileText, IconEye } from '@tabler/icons-react';

import { API } from '../../classes/API';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const jobColors: Record<string, string> = {
  engineer: 'blue',
  manager: 'cyan',
  designer: 'pink',
};


export default function EmailList() {
    const [emails, setEmails] = useState<emailProps[]>([]);
    // const [token, setToken] = useState('');
    const [cookies, setCookie] = useCookies(['token']);
    const [showPage, setShowPage] = useState(false);

    useEffect(
        () => {
            //Runs only on the first render
            const cookieToken = cookies.token
                API.get('/reauth', cookieToken).then(
                (response) =>  {
                    if (response.status === 401) {
                        setCookie("token", "")
                        window.location.replace('/admin/login');
                        return <></>
                    } else if (response.status === 200) {
              // Set the expiry date
              var date = new Date();
              var newDate = new Date(date.setMonth(date.getMonth() + 2));
              setCookie('token',response.body.token, {expires: newDate})
                        setShowPage(true)
                    }
                },
                error => {
                    console.log('ERROR?!?',error)
                }
            );

            API.get('/email',cookieToken).then((response) =>  {
                const jsonData = response.body;
                console.log('got family data',jsonData)
                setEmails(jsonData);
            });
            if (cookieToken === '') {
                window.location.replace('/admin/login');
                return
            }
        },
        []
    );

    const theme = useMantineTheme();
    const familyTable = (
        <>
            <h1>Email</h1>
            <ScrollArea>
                <Table sx={{ minWidth: 800 }} verticalSpacing="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Subject</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            emails.map((email) => (
                                <tr key={email.id}>
                                    <td>
                                        <Text fz="sm">
                                            {email.name}
                                        </Text>
                                    </td>
                                    <td>
                                        {email.subject}
                                    </td>
                                    <td>
                                        <Group spacing={0} position="right">


                                    <a href={'/admin/email/' + email.id}>
                                    <Tooltip
                                                label="Edit"
                                                color="blue"
                                                withArrow
                                                >
                                            <ActionIcon>
                                                <IconPencil size="1rem" stroke={1.5} />
                                            </ActionIcon>
                                            </Tooltip>
                                            </a>
                                        </Group>
                                    </td>
                                </tr>

                            ))
                        }
                    </tbody>
                </Table>
            </ScrollArea>
        </>
    );

    return (
        <>
        {showPage &&
        <AdminNav currentPage="Emails" content={familyTable} />
        }
        </>
    );
  }
